import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { yupResolver } from '@hookform/resolvers/yup';
import { DatePicker, LoadingButton, LocalizationProvider } from '@mui/lab';
import { Button, Checkbox, FormControl, FormControlLabel, InputLabel, MenuItem, Modal, Select, TextField, Typography } from "@mui/material";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { ja } from 'date-fns/locale';
import { ChangeEvent, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { FieldError, useForm, UseFormRegisterReturn } from "react-hook-form";
import {
  ContractKind,
  contractKindList,
  depositKind,
  depositKindType,
  initialProcedureType as InitialProcedureType,
  lessorType as LessorType,
  managementState as ManagementStatus,
  partition, paymentMethod,
  seasonalAdjustment as SeasonalAdjustment,
  taxCategory as TaxCategory
} from "../../../constant";
import { api } from "../../../infra/Api";
import { HttpStatus } from "../../../models/HttpStatus";
import { ContractDetail } from "../../../models/contract/ContractDetail";
import { diffFormData, FormData, getFormSchema, toFormData } from "../../../models/contract/ContractForm";
import { convertFromContractDetail } from "../../../models/contract/ContractToApi";
import { getFilenameAndDataUrl } from "../../../util";
import { ContractDetailEditFormType } from "./ContractDetailEditFormType";

export type ContractDetailEditModalProps = {
  formType?: ContractDetailEditFormType,
  onClose: (isUpdated: boolean) => void,
  contractDetail: ContractDetail,
  isAdmin?: boolean,
};

export const ContractDetailEditModal = ({
  formType,
  onClose,
  contractDetail,
  isAdmin,
}: ContractDetailEditModalProps) => {
  const isMounted = useRef(true);
  const [isUploading, setIsUploading] = useState(false);

  const contractKind = useMemo(() => ContractKind[contractDetail.contractKind], [contractDetail]);
  const { isAgencyAdmin, isAgencyUser, isDirectAdmin, isDirectUser, isDirectPdUser} = useMemo(() => ({
    isAgencyAdmin: contractKind === 'Agency' && isAdmin,
    isAgencyUser: contractKind === 'Agency' && !isAdmin,
    isDirectAdmin: ((contractKind === 'Direct') || (contractKind === 'DirectPd')) && isAdmin,
    isDirectUser: contractKind === 'Direct' && !isAdmin,
    isDirectPdUser: contractKind === 'DirectPd' && !isAdmin,
  }), [isAdmin, contractKind]);
  const formSchema = useMemo(() => getFormSchema(contractDetail.contractKind, isAdmin), [contractDetail, isAdmin]);
  const defaultValues = useMemo(() => toFormData<FormData>(contractDetail, formSchema), [contractDetail, formSchema]);

  const { clearErrors, formState: { errors, isDirty, isValid }, handleSubmit, register, reset, setValue, watch } = useForm({
    resolver: yupResolver(formSchema),
    mode: 'onTouched',
    defaultValues,
  });

  useEffect(() => {
    isMounted.current = true;
    return () => { isMounted.current = false; };
  }, []);

  useEffect(() => {
    if (isMounted.current) {
      reset(defaultValues);
    }
  }, [defaultValues, onClose, reset]);

  const onChangeCertificateFile = useCallback(async (event: ChangeEvent<HTMLInputElement>) => {
    const results = await getFilenameAndDataUrl(event.target.files?.[0]);
    if (results) {
      setValue(`carInfo.certificate`, results, { shouldValidate: true, shouldDirty: true });
    }
  }, []);

  const onChangePartitionImageFile = useCallback((number: 1 | 2 | 3) => async (event: ChangeEvent<HTMLInputElement>) => {
    const results = await getFilenameAndDataUrl(event.target.files?.[0]);
    if (results) {
      setValue(`clientCompany.partitionImage${number}`, results, { shouldValidate: true, shouldDirty: true });
    }
  }, []);

  const updateContract = useCallback(async (formData) => {
    try {
      if (contractKind === 'PD') return;

      setIsUploading(true);
      clearErrors();

      const diff = diffFormData(defaultValues, formData);
      if (Object.keys(diff).length === 0) return onClose(false);

      const param = convertFromContractDetail(diff);
      const [res, response] = await api.patch(
        isAdmin
          ? `/api/v1/admin/contract/${contractDetail.id}/`
          : `/api/v1/contract/${contractDetail.id}/`
        , param);

      if (isMounted.current) {
        if (res.status === HttpStatus.OK) {
          onClose(true);
        } else {
          showError(response);
        }
      }
    } finally {
      if (isMounted.current) {
        setIsUploading(false);
      }
    }
  }, [defaultValues, isAdmin, contractDetail, contractKind, clearErrors, onClose]);

  const isCarCertificateRegistered = useMemo(() => !!(defaultValues.carInfo && (defaultValues.carInfo.certificate || defaultValues.carInfo.certificatePath)), [defaultValues.carInfo]);
  const isPartitionImage1Registered = useMemo(() => !!(defaultValues.clientCompany && defaultValues.clientCompany.partitionImage1), [defaultValues.clientCompany]);
  const isPartitionImage2Registered = useMemo(() => !!(defaultValues.clientCompany && defaultValues.clientCompany.partitionImage2), [defaultValues.clientCompany]);
  const isPartitionImage3Registered = useMemo(() => !!(defaultValues.clientCompany && defaultValues.clientCompany.partitionImage3), [defaultValues.clientCompany]);

  const isDisplayCarCertificateFileChooser = useMemo(() => {
    if (isAdmin || isDirectUser || isDirectPdUser) return true;
    return false;
  }, [isAdmin, isDirectUser, isDirectPdUser]);

  const isDisplayPartitionImageFileChooser = useMemo(() => {
    if (isAdmin || isDirectUser) return true;
    return false;
  }, [isAdmin, isDirectUser]);

  const noneDisplayStyle = isAdmin ? "" : "none";

  const rentalItem = watch('rentalItem');
  const issueDate = watch('contract.issueDate');
  const updateBaseFeeWithTax = watch('contract.updateBaseFeeWithTax');
  const updateManagementFeeWithTax = watch('contract.updateManagementFeeWithTax');
  const renewalManagementFeeWithTax = watch('contract.renewalManagementFeeWithTax');

  const totalRenewalFee =
    (Number(updateBaseFeeWithTax) || 0)
    + (Number(updateManagementFeeWithTax) || 0)
    + (Number(renewalManagementFeeWithTax) || 0);

  return (
    <Modal open={!!formType} css={modalStyle}>
      <div css={modalContentStyle}>
        {formType === 'basic_information' && (
          <>
            <div css={modalTitleStyle}>基本情報</div>
            <div css={rowStyle}>
              <StyledTextField label="契約形態" value={contractKindList[contractDetail.contractKind]} disabled />
              <FormControl css={selectFormStyle} disabled={isAgencyUser || isDirectPdUser}>
                <InputLabel id="managementState" variant="standard">契約状態</InputLabel>
                <Select label="契約状態" labelId="managementState" variant="standard" {...registerMui(register('managementState'))} error={!!errors.managementState} defaultValue={defaultValues.managementState ?? ''}>
                  <MenuItem value=""></MenuItem>
                  {ManagementStatus.map((value, index) => (<MenuItem key={index} value={index}>{value}</MenuItem>))}
                </Select>
              </FormControl>
            </div>
            <div css={rowStyle} style={{ display: noneDisplayStyle }}>
              <FormControlLabel
                label={<StyledCheckBoxLabel>通常契約</StyledCheckBoxLabel>}
                control={<StyledCheckBox {...registerMui(register('normalContract'))} defaultChecked={defaultValues.normalContract} disabled={isAgencyUser || isDirectUser || isDirectPdUser} />}
              />
            </div>
            <div css={rowStyle}>
              <StyledTextField type="date" label="契約日" {...registerMui(register('contract.contractedDate'))} error={!!errors.contract?.contractedDate} helperText={(errors.contract?.contractedDate as FieldError)?.message} disabled={isAgencyUser || isDirectPdUser} />
              <StyledTextField type="date" label="解約日" {...registerMui(register('contract.cancelDate'))} error={!!errors.contract?.cancelDate} helperText={(errors.contract?.cancelDate as FieldError)?.message} disabled={isAgencyUser || isDirectPdUser} />
            </div>
            <div css={rowStyle}>
              <StyledTextField type="number" label="契約期間" {...registerMui(register('contract.contractPeriodMonth'))} error={!!errors.contract?.contractPeriodMonth} helperText={errors.contract?.contractPeriodMonth?.message} disabled={isAgencyUser || isDirectPdUser} />
              <StyledTextField type="date" label="初回契約開始日" {...registerMui(register('contract.firstStartDate'))} error={!!errors.contract?.firstStartDate} helperText={(errors.contract?.firstStartDate as FieldError)?.message} disabled={isAgencyUser || isDirectPdUser} />
            </div>
            <div css={rowStyle}>
              <StyledTextField type="date" label="賃貸借期間_開始" {...registerMui(register('contract.startDate'))} error={!!errors.contract?.startDate} helperText={(errors.contract?.startDate as FieldError)?.message} disabled={isAgencyUser || isDirectPdUser} />
              <StyledTextField type="date" label="賃貸借期間_終了" {...registerMui(register('contract.endDate'))} error={!!errors.contract?.endDate} helperText={(errors.contract?.endDate as FieldError)?.message} disabled={isAgencyUser || isDirectPdUser} />
            </div>
            <div css={rowStyle}>
              {/* 保管場所使用承諾証明書 ひとまず消さずにコメントアウトとしておく */}
              {/*
              <FormControl css={selectFormStyle} disabled={isAgencyUser || isDirectUser || isDirectPdUser}>
                <InputLabel id="needIssuePaper" variant="standard">保管場所使用承諾証明書</InputLabel>
                <Select label="保管場所使用承諾証明書" labelId="needIssuePaper" variant="standard" value={+contract.needIssuePaper} onChange={onChangeNeedIssuePaper}>
                  <MenuItem value={1}>必要</MenuItem>
                  <MenuItem value={0}>不要</MenuItem>
                </Select>
              </FormControl>
              */}
              <LocalizationProvider dateAdapter={AdapterDateFns} locale={ja}>
                <DatePicker
                  views={['year', 'month']}
                  label="保管場所使用承諾証明書の発行月"
                  inputFormat="yyyy/MM"
                  openTo="month"
                  value={issueDate}
                  onChange={(value) => setValue('contract.issueDate', value, { shouldValidate: true, shouldDirty: true })}
                  renderInput={(params) => 
                    <StyledTextField
                      {...params}
                      error={!!errors.contract?.issueDate}
                      helperText={(errors.contract?.issueDate as FieldError)?.message} />
                  }
                  disabled={isAgencyUser}
                />
              </LocalizationProvider>
              <StyledTextField type="date" label="初回支払日" {...registerMui(register('contract.initialPaymentDate'))} error={!!errors.contract?.initialPaymentDate} helperText={(errors.contract?.initialPaymentDate as FieldError)?.message} disabled={isAgencyUser || isDirectPdUser} />
            </div>
            <div css={rowStyle}>
              <FormControl css={selectFormStyle} disabled={isAgencyUser || isDirectPdUser}>
                <InputLabel id="initialProcedureType" variant="standard" shrink>初回契約手続き区分</InputLabel>
                <Select label="初回契約手続き区分" labelId="initialProcedureType" variant="standard" {...registerMui(register('initialProcedureType'))} error={!!errors.initialProcedureType} defaultValue={defaultValues.initialProcedureType ?? ''}>
                  <MenuItem value=""></MenuItem>
                  {InitialProcedureType.map((value, index) => (<MenuItem key={index} value={index}>{value}</MenuItem>))}
                </Select>
              </FormControl>
            </div>
            <div css={rowStyle}>
              <FormControlLabel
                label={<StyledCheckBoxLabel disabled={isAgencyUser || isDirectPdUser}>自動更新</StyledCheckBoxLabel>}
                control={<StyledCheckBox {...registerMui(register('autoUpdate'))} defaultChecked={defaultValues.autoUpdate} disabled={isAgencyUser || isDirectPdUser} />}
              />
            </div>
          </>
        )}
        {formType === 'user_information' && (
          <>
            <div css={modalTitleStyle}>利用者情報</div>
            <div css={rowStyle}>
              <StyledTextField label="利用者名" {...registerMui(register('user.name'))} error={!!errors.user?.name} helperText={errors.user?.name?.message} disabled={isAgencyUser} />
              <StyledTextField label="利用者名(カナ)" {...registerMui(register('user.nameKana'))} error={!!errors.user?.nameKana} helperText={errors.user?.nameKana?.message} disabled={isAgencyUser} />
            </div>
            <div css={rowStyle}>
              <StyledTextField label="利用-担当者名" {...registerMui(register('user.manager'))} error={!!errors.user?.manager} helperText={errors.user?.manager?.message} disabled={isAgencyUser} />
              <StyledTextField type="email" label="利用者メールアドレス" {...registerMui(register('user.email'))} error={!!errors.user?.email} helperText={errors.user?.email?.message} disabled={isAgencyUser} />
            </div>
            <div css={rowStyle}>
              <StyledTextField label="利用者携帯電話番号" {...registerMui(register('user.mobilePhoneNumber'))} error={!!errors.user?.mobilePhoneNumber} helperText={errors.user?.mobilePhoneNumber?.message} disabled={isAgencyUser} />
              <StyledTextField label="利用者自宅電話番号" {...registerMui(register('user.phoneNumber'))} error={!!errors.user?.phoneNumber} helperText={errors.user?.phoneNumber?.message} disabled={isAgencyUser} />
            </div>
            <div css={rowStyle}>
              <StyledTextField label="利用者従業員番号" {...registerMui(register('user.employeeNumber'))} error={!!errors.user?.employeeNumber} helperText={errors.user?.employeeNumber?.message} />
              <div />
            </div>
            <div css={rowStyle}>
              <StyledTextField label="利用者所属拠点名" {...registerMui(register('user.affiliationBranchName'))} error={!!errors.user?.affiliationBranchName} helperText={errors.user?.affiliationBranchName?.message} />
              <StyledTextField label="利用者所属拠点番号" {...registerMui(register('user.affiliationBranchNumber'))} error={!!errors.user?.affiliationBranchNumber} helperText={errors.user?.affiliationBranchNumber?.message} />
            </div>
            <div css={rowStyle}>
              <StyledTextField label="利用者所属組織名" {...registerMui(register('user.affiliationDepartmentName'))} error={!!errors.user?.affiliationDepartmentName} helperText={errors.user?.affiliationDepartmentName?.message} />
              <StyledTextField label="利用者所属組織番号" {...registerMui(register('user.affiliationDepartmentNumber'))} error={!!errors.user?.affiliationDepartmentNumber} helperText={errors.user?.affiliationDepartmentNumber?.message} />
            </div>
          </>
        )}
        {formType === 'contractor_information' && (
          <>
            <div css={modalTitleStyle}>契約者情報</div>
            <div css={rowStyle}>
              <StyledTextField label="契約者名" {...registerMui(register('contractor.name'))} error={!!errors.contractor?.name} helperText={errors.contractor?.nameKana?.message} disabled={isAgencyUser || isDirectPdUser} />
              <StyledTextField label="契約者名(カナ)" {...registerMui(register('contractor.nameKana'))} error={!!errors.contractor?.nameKana} helperText={errors.contractor?.nameKana?.message} disabled={isAgencyUser || isDirectPdUser} />
            </div>
            <div css={rowStyle}>
              <StyledTextField label="契約-担当者名" {...registerMui(register('contractor.manager'))} error={!!errors.contractor?.manager} helperText={errors.contractor?.manager?.message} disabled={isAgencyUser || isDirectPdUser} />
              <StyledTextField label="契約者メールアドレス" {...registerMui(register('contractor.email'))} error={!!errors.contractor?.email} helperText={errors.contractor?.email?.message} disabled={isAgencyUser || isDirectPdUser} />
            </div>
            <div css={rowStyle}>
              <StyledTextField label="契約者電話番号" {...registerMui(register('contractor.phoneNumber'))} error={!!errors.contractor?.phoneNumber} helperText={errors.contractor?.phoneNumber?.message} disabled={isAgencyUser || isDirectPdUser} />
              <StyledTextField label="契約者会社電話番号" {...registerMui(register('contractor.companyPhoneNumber'))} error={!!errors.contractor?.companyPhoneNumber} helperText={errors.contractor?.companyPhoneNumber?.message} disabled={isAgencyUser || isDirectPdUser} />
            </div>
            <div css={rowStyle}>
              <StyledTextField label="契約者従業員番号" {...registerMui(register('contractor.employeeNumber'))} error={!!errors.contractor?.employeeNumber} helperText={errors.contractor?.employeeNumber?.message} />
              <div />
            </div>
            <div css={rowStyle}>
              <StyledTextField label="契約者所属拠点名" {...registerMui(register('contractor.affiliationBranchName'))} error={!!errors.contractor?.affiliationBranchName} helperText={errors.contractor?.affiliationBranchName?.message} />
              <StyledTextField label="契約者所属拠点番号" {...registerMui(register('contractor.affiliationBranchNumber'))} error={!!errors.contractor?.affiliationBranchNumber} helperText={errors.contractor?.affiliationBranchNumber?.message} />
            </div>
            <div css={rowStyle}>
              <StyledTextField label="契約者所属組織名" {...registerMui(register('contractor.affiliationDepartmentName'))} error={!!errors.contractor?.affiliationDepartmentName} helperText={errors.contractor?.affiliationDepartmentName?.message} />
              <StyledTextField label="契約者所属組織番号" {...registerMui(register('contractor.affiliationDepartmentNumber'))} error={!!errors.contractor?.affiliationDepartmentNumber} helperText={errors.contractor?.affiliationDepartmentNumber?.message} />
            </div>
          </>
        )}
        {formType === 'parking_information' && (
          <>
            <div css={modalTitleStyle}>駐車場情報</div>
            <div css={rowStyle}>
              <StyledTextField label="駐車場名" {...registerMui(register('clientCompany.parkingName'))} error={!!errors.clientCompany?.parkingName} helperText={errors.clientCompany?.parkingName?.message} disabled={isAgencyUser || isDirectPdUser} />
              <StyledTextField label="区画番号" {...registerMui(register('clientCompany.partition'))} error={!!errors.clientCompany?.partition} helperText={errors.clientCompany?.partition?.message} disabled={isAgencyUser || isDirectPdUser} />
            </div>
            <div css={rowStyle}>
              <FormControl css={selectFormStyle} disabled={isAgencyUser || isDirectPdUser}>
                <InputLabel id="partitionType" variant="standard">区画種別</InputLabel>
                <Select label="区画種別" labelId="partitionType" variant="standard" {...registerMui(register('clientCompany.partitionType'))} error={!!errors.clientCompany?.partitionType} defaultValue={defaultValues.clientCompany?.partitionType ?? ''}>
                  <MenuItem value=""></MenuItem>
                  {partition.map((value, index) => (<MenuItem key={index} value={index}>{value}</MenuItem>))}
                </Select>
              </FormControl>
              <StyledTextField label="住所" {...registerMui(register('clientCompany.address'))} error={!!errors.clientCompany?.address} helperText={errors.clientCompany?.address?.message} disabled={isAgencyUser || isDirectPdUser} />
            </div>
            <div css={rowStyle}>
              <StyledTextField label="緯度" {...registerMui(register('clientCompany.latitude'))} error={!!errors.clientCompany?.latitude} helperText={errors.clientCompany?.latitude?.message} disabled={isAgencyUser || isDirectPdUser} />
              <StyledTextField label="経度" {...registerMui(register('clientCompany.longitude'))} error={!!errors.clientCompany?.longitude} helperText={errors.clientCompany?.longitude?.message} disabled={isAgencyUser || isDirectPdUser} />
            </div>
            <div css={rowStyle}>
              <div css={inputFileColStyle}>
                <StyledFileField label="区画図1" value={isPartitionImage1Registered ? '登録あり' : '登録なし'} disabled />
                {isDisplayPartitionImageFileChooser && <div css={partitionImageInputFileStyle}><input type="file" accept="image/*,.pdf" onChange={onChangePartitionImageFile(1)} /></div>}
              </div>
              <div css={inputFileColStyle}>
                <StyledFileField label="区画図2" value={isPartitionImage2Registered ? '登録あり' : '登録なし'} disabled />
                {isDisplayPartitionImageFileChooser && <div css={partitionImageInputFileStyle}><input type="file" accept="image/*,.pdf" onChange={onChangePartitionImageFile(2)} /></div>}
              </div>
            </div>
            <div css={rowStyle}>
              <div css={inputFileColStyle}>
                <StyledFileField label="区画図3" value={isPartitionImage3Registered ? '登録あり' : '登録なし'} disabled />
                {isDisplayPartitionImageFileChooser && <div css={partitionImageInputFileStyle}><input type="file" accept="image/*,.pdf" onChange={onChangePartitionImageFile(3)} /></div>}
              </div>
              <div css={inputFileColStyle} />
            </div>
            <div css={rowStyle}>
              <FormControl css={selectFormStyle} disabled={isAgencyUser || isDirectPdUser}>
                <InputLabel id="rentalItem" variant="standard">貸与物</InputLabel>
                <Select label="貸与物" labelId="rentalItem" variant="standard" {...registerMui(register('rentalItem'))} error={!!errors.rentalItem} defaultValue={defaultValues.rentalItem ? 1 : 0}>
                  <MenuItem value={0}>なし</MenuItem>
                  <MenuItem value={1}>あり</MenuItem>
                </Select>
              </FormControl>
              <StyledTextField label="貸与物の内容" {...registerMui(register('rentalItemContents'))} error={!!errors?.rentalItemContents} disabled={(isAgencyUser || isDirectPdUser) || !rentalItem} />
            </div>
          </>
        )}
        {formType === 'management_company_information' && (
          <>
            <div css={modalTitleStyle}>管理会社情報</div>
            <div css={rowStyle}>
              <StyledTextField label="管理会社名" {...registerMui(register('clientCompany.clientCompanyName'))} error={!!errors.clientCompany?.clientCompanyName} helperText={errors.clientCompany?.clientCompanyName?.message} disabled={isAgencyUser || isDirectPdUser} />
              <StyledTextField label="店舗名" {...registerMui(register('clientCompany.clientStoreName'))} error={!!errors.clientCompany?.clientStoreName} helperText={errors.clientCompany?.clientStoreName?.message} disabled={isAgencyUser || isDirectPdUser} />
            </div>
            <div css={rowStyle}>
              <StyledTextField label="管理会社郵便番号" {...registerMui(register('clientCompany.clientCompanyPostalCode'))} error={!!errors.clientCompany?.clientCompanyPostalCode} helperText={errors.clientCompany?.clientCompanyPostalCode?.message} disabled={isAgencyUser || isDirectPdUser} />
              <StyledTextField label="管理会社住所" {...registerMui(register('clientCompany.clientCompanyAddress'))} error={!!errors.clientCompany?.clientCompanyAddress} helperText={errors.clientCompany?.clientCompanyAddress?.message} disabled={isAgencyUser || isDirectPdUser} />
            </div>
            <div css={rowStyle}>
              <StyledTextField label="管理会社電話番号" {...registerMui(register('clientCompany.phoneNumber'))} error={!!errors.clientCompany?.phoneNumber} helperText={errors.clientCompany?.phoneNumber?.message} disabled={isAgencyUser || isDirectPdUser} />
              <StyledTextField label="管理会社FAX番号" {...registerMui(register('clientCompany.faxNumber'))} error={!!errors.clientCompany?.faxNumber} helperText={errors.clientCompany?.faxNumber?.message} disabled={isAgencyUser || isDirectPdUser} />
            </div>
            <div css={rowStyle}>
              <StyledTextField label="担当者名" {...registerMui(register('clientCompany.staffName'))} error={!!errors.clientCompany?.staffName} helperText={errors.clientCompany?.staffName?.message} disabled={isAgencyUser || isDirectPdUser} />
              <StyledTextField label="担当者メールアドレス" {...registerMui(register('clientCompany.staffEmail'))} error={!!errors.clientCompany?.staffEmail} helperText={errors.clientCompany?.staffEmail?.message} disabled={isAgencyUser || isDirectPdUser} />
            </div>
            <div css={rowStyle}>
              <StyledTextField label="インボイス発行事業者の氏名または名称および登録番号" {...registerMui(register('clientCompany.invoiceNameOrNumber'))} error={!!errors.clientCompany?.invoiceNameOrNumber} helperText={errors.clientCompany?.invoiceNameOrNumber?.message} disabled={isAgencyUser || isDirectPdUser} />
              <StyledTextField type="number" label="適用税率" {...registerMui(register('clientCompany.invoiceTaxRate'))} error={!!errors.clientCompany?.invoiceTaxRate} helperText={errors.clientCompany?.invoiceTaxRate?.message} disabled={isAgencyUser || isDirectPdUser} />
            </div>
            <div css={rowStyle}>
              <StyledTextField label="書類の交付を受ける事業者の氏名または名称" {...registerMui(register('clientCompany.invoiceReceiverName'))} error={!!errors.clientCompany?.invoiceReceiverName} helperText={errors.clientCompany?.invoiceReceiverName?.message} disabled={isAgencyUser || isDirectPdUser} />
            </div>
            <div css={rowStyle}>
              <StyledTextField label="保証会社名" {...registerMui(register('clientCompany.guarantorCompanyName'))} error={!!errors.clientCompany?.guarantorCompanyName} helperText={errors.clientCompany?.guarantorCompanyName?.message} disabled={isAgencyUser || isDirectPdUser} />
              <StyledTextField label="保証会社インボイス登録番号" {...registerMui(register('clientCompany.guarantorInvoiceNumber'))} error={!!errors.clientCompany?.guarantorInvoiceNumber} helperText={errors.clientCompany?.guarantorInvoiceNumber?.message} disabled={isAgencyUser || isDirectPdUser} />
            </div>
            <div css={rowStyle}>
              <StyledTextField label="備考" {...registerMui(register('clientCompany.note'))} error={!!errors.clientCompany?.note} helperText={errors.clientCompany?.note?.message} disabled={isAgencyUser || isDirectPdUser} />
            </div>
          </>
        )}
        {formType === 'lessor' && (
          <>
            <div css={modalTitleStyle}>貸主情報</div>
            <div css={rowStyle}>
              <StyledTextField label="貸主名" {...registerMui(register('lessor.lessorName'))} error={!!errors.lessor?.lessorName} helperText={errors.lessor?.lessorName?.message} disabled={isAgencyUser || isDirectPdUser} />
              <FormControl css={selectFormStyle} disabled={isAgencyUser || isDirectPdUser}>
                <InputLabel id="lessorType" variant="standard" shrink>貸主区分</InputLabel>
                <Select label="貸主区分" labelId="lessorType" variant="standard" {...registerMui(register('lessor.lessorType'))} error={!!errors.lessor?.lessorType} defaultValue={defaultValues.lessor?.lessorType ?? ''}>
                  <MenuItem value=""></MenuItem>
                  {LessorType.map((value, index) => (<MenuItem key={index} value={index}>{value}</MenuItem>))}
                </Select>
              </FormControl>
            </div>
            <div css={rowStyle}>
              <StyledTextField label="貸主郵便番号" {...registerMui(register('lessor.postalCode'))} error={!!errors.lessor?.postalCode} helperText={errors.lessor?.postalCode?.message} disabled={isAgencyUser || isDirectPdUser} />
              <StyledTextField label="貸主住所" {...registerMui(register('lessor.address'))} error={!!errors.lessor?.address} helperText={errors.lessor?.address?.message} disabled={isAgencyUser || isDirectPdUser} />
            </div>
            <div css={rowStyle}>
              <StyledTextField label="貸主電話番号" {...registerMui(register('lessor.phoneNumber'))} error={!!errors.lessor?.phoneNumber} helperText={errors.lessor?.phoneNumber?.message} disabled={isAgencyUser || isDirectPdUser} />
              <StyledTextField label="貸主FAX番号" {...registerMui(register('lessor.faxNumber'))} error={!!errors.lessor?.faxNumber} helperText={errors.lessor?.faxNumber?.message} disabled={isAgencyUser || isDirectPdUser} />
            </div>
            <div css={rowStyle}>
              <StyledTextField label="インボイス発行事業者の氏名または名称および登録番号" {...registerMui(register('lessor.invoiceNameOrNumber'))} error={!!errors.lessor?.invoiceNameOrNumber} helperText={errors.lessor?.invoiceNameOrNumber?.message} disabled={isAgencyUser || isDirectPdUser} />
              <StyledTextField label="貸主メールアドレス" {...registerMui(register('lessor.email'))} error={!!errors.lessor?.email} helperText={errors.lessor?.email?.message} disabled={isAgencyUser || isDirectPdUser} />
            </div>
            <div css={rowStyle}>
              <StyledTextField type="number" label="適用税率" {...registerMui(register('lessor.invoiceTaxRate'))} error={!!errors.lessor?.invoiceTaxRate} helperText={errors.lessor?.invoiceTaxRate?.message} disabled={isAgencyUser || isDirectPdUser} />
              <StyledTextField label="書類の交付を受ける事業者の氏名または名称" {...registerMui(register('lessor.invoiceReceiverName'))} error={!!errors.lessor?.invoiceReceiverName} helperText={errors.lessor?.invoiceReceiverName?.message} disabled={isAgencyUser || isDirectPdUser} />
            </div>
            <div css={rowStyle}>
              <StyledTextField label="備考" {...registerMui(register('lessor.note'))} error={!!errors.lessor?.note} helperText={errors.lessor?.note?.message} disabled={isAgencyUser || isDirectPdUser} />
            </div>
          </>
        )}
        {formType === 'broker_company' && (
          <>
            <div css={modalTitleStyle}>仲介会社情報</div>
            <div css={rowStyle}>
              <StyledTextField label="仲介会社名" {...registerMui(register('brokerCompany.brokerCompanyName'))} error={!!errors.brokerCompany?.brokerCompanyName} helperText={errors.brokerCompany?.brokerCompanyName?.message} disabled={isAgencyUser || isDirectPdUser} />
            </div>
            <div css={rowStyle}>
              <StyledTextField label="仲介会社郵便番号" {...registerMui(register('brokerCompany.postalCode'))} error={!!errors.brokerCompany?.postalCode} helperText={errors.brokerCompany?.postalCode?.message} disabled={isAgencyUser || isDirectPdUser} />
              <StyledTextField label="仲介会社住所" {...registerMui(register('brokerCompany.address'))} error={!!errors.brokerCompany?.address} helperText={errors.brokerCompany?.address?.message} disabled={isAgencyUser || isDirectPdUser} />
            </div>
            <div css={rowStyle}>
              <StyledTextField label="仲介会社電話番号" {...registerMui(register('brokerCompany.phoneNumber'))} error={!!errors.brokerCompany?.phoneNumber} helperText={errors.brokerCompany?.phoneNumber?.message} disabled={isAgencyUser || isDirectPdUser} />
              <StyledTextField label="仲介会社FAX番号" {...registerMui(register('brokerCompany.faxNumber'))} error={!!errors.brokerCompany?.faxNumber} helperText={errors.brokerCompany?.faxNumber?.message} disabled={isAgencyUser || isDirectPdUser} />
            </div>
            <div css={rowStyle}>
              <StyledTextField label="仲介会社メールアドレス" {...registerMui(register('brokerCompany.email'))} error={!!errors.brokerCompany?.email} helperText={errors.brokerCompany?.email?.message} disabled={isAgencyUser || isDirectPdUser} />
              <StyledTextField label="インボイス発行事業者の氏名または名称および登録番号" {...registerMui(register('brokerCompany.invoiceNameOrNumber'))} error={!!errors.brokerCompany?.invoiceNameOrNumber} helperText={errors.brokerCompany?.invoiceNameOrNumber?.message} disabled={isAgencyUser || isDirectPdUser} />
            </div>
            <div css={rowStyle}>
              <StyledTextField type="number" label="適用税率" {...registerMui(register('brokerCompany.invoiceTaxRate'))} error={!!errors.brokerCompany?.invoiceTaxRate} helperText={errors.brokerCompany?.invoiceTaxRate?.message} disabled={isAgencyUser || isDirectPdUser} />
              <StyledTextField label="書類の交付を受ける事業者の氏名または名称" {...registerMui(register('brokerCompany.invoiceReceiverName'))} error={!!errors.brokerCompany?.invoiceReceiverName} helperText={errors.brokerCompany?.invoiceReceiverName?.message} disabled={isAgencyUser || isDirectPdUser} />
            </div>
            <div css={rowStyle}>
              <StyledTextField label="備考" {...registerMui(register('brokerCompany.note'))} error={!!errors.brokerCompany?.note} helperText={errors.brokerCompany?.note?.message} disabled={isAgencyUser || isDirectPdUser} />
            </div>
          </>
        )}
        {formType === 'vehicle_information' && (
          <>
            <div css={modalTitleStyle}>契約車両情報</div>
            <div css={rowStyle}>
              <StyledTextField label="車種" {...registerMui(register('carInfo.carModel'))} error={!!errors.carInfo?.carModel} helperText={errors.carInfo?.carModel?.message} disabled={isAgencyUser} />
              <StyledTextField label="車種-登録番号（地域名）" {...registerMui(register('carInfo.regnoRegionName'))} error={!!errors.carInfo?.regnoRegionName} helperText={errors.carInfo?.regnoRegionName?.message} disabled={isAgencyUser} />
            </div>
            <div css={rowStyle}>
              <StyledTextField label="車種-登録番号（分類番号3桁）" {...registerMui(register('carInfo.regnoClassDigit'))} error={!!errors.carInfo?.regnoClassDigit} helperText={errors.carInfo?.regnoClassDigit?.message} disabled={isAgencyUser} />
              <StyledTextField label="車種-登録番号（平仮名等）" {...registerMui(register('carInfo.regnoHiragana'))} error={!!errors.carInfo?.regnoHiragana} helperText={errors.carInfo?.regnoHiragana?.message} disabled={isAgencyUser} />
            </div>
            <div css={rowStyle}>
              <StyledTextField label="車種-登録番号(一連指定番号4桁)" {...registerMui(register('carInfo.regnoSeqDigit'))} error={!!errors.carInfo?.regnoSeqDigit} helperText={errors.carInfo?.regnoSeqDigit?.message} disabled={isAgencyUser} />
              <StyledTextField label="車検証" value={isCarCertificateRegistered ? '登録あり' : '登録なし'} disabled />
              {isDisplayCarCertificateFileChooser && <div css={certificateInputFileStyle}><input type="file" accept="image/*,.pdf" onChange={onChangeCertificateFile} /></div>}
            </div>
          </>
        )}
        {formType === 'notice_memo' && (
          <>
            <div css={modalTitleStyle}>お知らせ・注意喚起のメモ</div>
            <div css={rowStyle}>
              <StyledTextField label="メモ1" {...registerMui(register('contractAttribution.noticeMemo1'))} error={!!errors.contractAttribution?.noticeMemo1} helperText={errors.contractAttribution?.noticeMemo1?.message} disabled={isAgencyUser || isDirectUser || isDirectPdUser} />
              <StyledTextField label="メモ2" {...registerMui(register('contractAttribution.noticeMemo2'))} error={!!errors.contractAttribution?.noticeMemo2} helperText={errors.contractAttribution?.noticeMemo2?.message} disabled={isAgencyUser || isDirectUser || isDirectPdUser} />
            </div>
            <div css={rowStyle}>
              <StyledTextField label="メモ3" {...registerMui(register('contractAttribution.noticeMemo3'))} error={!!errors.contractAttribution?.noticeMemo3} helperText={errors.contractAttribution?.noticeMemo3?.message} disabled={isAgencyUser || isDirectUser || isDirectPdUser} />
            </div>
          </>
        )}
        {formType === 'note' && (
          <>
            <div css={modalTitleStyle}>備考</div>
            <div css={rowStyle}>
              <StyledTextField label="メモ1" {...registerMui(register('contractAttribution.memo1'))} error={!!errors.contractAttribution?.memo1} helperText={errors.contractAttribution?.memo1?.message} />
              <StyledTextField label="メモ2" {...registerMui(register('contractAttribution.memo2'))} error={!!errors.contractAttribution?.memo2} helperText={errors.contractAttribution?.memo2?.message} />
            </div>
            <div css={rowStyle}>
              <StyledTextField label="メモ3" {...registerMui(register('contractAttribution.memo3'))} error={!!errors.contractAttribution?.memo3} helperText={errors.contractAttribution?.memo3?.message} />
              <StyledTextField label="使用用途" {...registerMui(register('contractAttribution.usage'))} error={!!errors.contractAttribution?.usage} helperText={errors.contractAttribution?.usage?.message} />
            </div>
          </>
        )}
        {formType === 'payment_information' && (
          <>
            <div css={modalTitleStyle}>契約条件</div>
            <div css={rowStyle}>
              <FormControl css={selectFormStyle} disabled={isAgencyUser || isDirectPdUser}>
                <InputLabel id="taxCategory" variant="standard" shrink>税区分</InputLabel>
                <Select label="税区分" labelId="taxCategory" variant="standard"
                  {...registerMui(register('contract.taxCategory'))} error={!!errors.contract?.taxCategory} defaultValue={defaultValues.contract?.taxCategory ?? ''}>
                  <MenuItem value=""></MenuItem>
                  {TaxCategory.map((value, index) => (<MenuItem key={index} value={index}>{value}</MenuItem>))}
                </Select>
              </FormControl>
              <StyledTextField type="number" label="月額賃料(税込)" {...registerMui(register('contract.rentWithTax'))} error={!!errors.contract?.rentWithTax} helperText={errors.contract?.rentWithTax?.message} disabled={isAgencyUser || isDirectPdUser} />
            </div>
            <div css={rowStyle}>
              <StyledTextField type="number" label="月額賃料(税抜)" {...registerMui(register('contract.rentWithoutTax'))} error={!!errors.contract?.rentWithoutTax} helperText={errors.contract?.rentWithoutTax?.message} disabled={isAgencyUser || isDirectPdUser} />
              <StyledTextField type="number" label="初月賃料(税込)" {...registerMui(register('contract.firstMonthRentWithTax'))} error={!!errors.contract?.firstMonthRentWithTax} helperText={errors.contract?.firstMonthRentWithTax?.message} disabled={isAgencyUser || isDirectPdUser} />
            </div>
            <div css={rowStyle}>
              <StyledTextField type="number" label="最終月賃料(税込)" {...registerMui(register('contract.finalMonthRentWithTax'))} error={!!errors.contract?.finalMonthRentWithTax} helperText={errors.contract?.finalMonthRentWithTax?.message} disabled={isAgencyUser || isDirectPdUser} />
              <StyledTextField type="number" label="初月賃料(税抜)" {...registerMui(register('contract.firstMonthRentWithoutTax'))} error={!!errors.contract?.firstMonthRentWithoutTax} helperText={errors.contract?.firstMonthRentWithoutTax?.message} disabled={isAgencyUser || isDirectPdUser} />
            </div>
            <div css={rowStyle}>
              <StyledTextField type="number" label="最終月賃料(税抜)" {...registerMui(register('contract.finalMonthRentWithoutTax'))} error={!!errors.contract?.finalMonthRentWithoutTax} helperText={errors.contract?.finalMonthRentWithoutTax?.message} disabled={isAgencyUser || isDirectPdUser} />
              <StyledTextField type="number" label="月額保証／管理料(税込)" {...registerMui(register('contract.monthlyGuaranteeAndManagementFeeWithTax'))} error={!!errors.contract?.monthlyGuaranteeAndManagementFeeWithTax} helperText={errors.contract?.monthlyGuaranteeAndManagementFeeWithTax?.message} disabled={isAgencyUser || isDirectPdUser} />
            </div>
            <div css={rowStyle}>
              <StyledTextField type="number" label="初回保証／管理料(税込)" {...registerMui(register('contract.initialGuaranteeAndManagementFeeWithTax'))} error={!!errors.contract?.initialGuaranteeAndManagementFeeWithTax} helperText={errors.contract?.initialGuaranteeAndManagementFeeWithTax?.message} disabled={isAgencyUser || isDirectPdUser} />
              <StyledTextField type="number" label="初月保証／管理料(税込)" {...registerMui(register('contract.firstMonthGuaranteeAndManagementFeeWithTax'))} error={!!errors.contract?.firstMonthGuaranteeAndManagementFeeWithTax} helperText={errors.contract?.firstMonthGuaranteeAndManagementFeeWithTax?.message} disabled={isAgencyUser || isDirectPdUser} />
            </div>
            <div css={rowStyle}>
              <StyledTextField type="number" label="最終月保証／管理料(税込)" {...registerMui(register('contract.finalMonthGuaranteeAndManagementFeeWithTax'))} error={!!errors.contract?.finalMonthGuaranteeAndManagementFeeWithTax} helperText={errors.contract?.finalMonthGuaranteeAndManagementFeeWithTax?.message} disabled={isAgencyUser || isDirectPdUser} />
              <StyledTextField type="number" label="敷金(非課税)" {...registerMui(register('contract.depositWithTax'))} error={!!errors.contract?.depositWithTax} helperText={errors.contract?.depositWithTax?.message} disabled={isAgencyUser || isDirectPdUser} />
            </div>
            <div css={rowStyle}>
              <StyledTextField type="number" label="礼金(税込)" {...registerMui(register('contract.keyMoneyWithTax'))} error={!!errors.contract?.keyMoneyWithTax} helperText={errors.contract?.keyMoneyWithTax?.message} disabled={isAgencyUser || isDirectPdUser} />
              <StyledTextField type="number" label="事務手数料(税込)" {...registerMui(register('contract.administrativeFeeWithTax'))} error={!!errors.contract?.administrativeFeeWithTax} helperText={errors.contract?.administrativeFeeWithTax?.message} disabled={isAgencyUser || isDirectPdUser} />
            </div>
            <div css={rowStyle}>
              <StyledTextField type="number" label="仲介手数料(税込)" {...registerMui(register('contract.brokerageFeeWithTax'))} error={!!errors.contract?.brokerageFeeWithTax} helperText={errors.contract?.brokerageFeeWithTax?.message} disabled={isAgencyUser || isDirectPdUser} />
              <StyledTextField type="number" label="初回保証料(非課税)" {...registerMui(register('contract.initialGuaranteeFeeWithoutTax'))} error={!!errors.contract?.initialGuaranteeFeeWithoutTax} helperText={errors.contract?.initialGuaranteeFeeWithoutTax?.message} disabled={isAgencyUser || isDirectPdUser} />
            </div>
            <div css={rowStyle}>
              <StyledTextField type="number" label="月額保証料(非課税)" {...registerMui(register('contract.monthlyGuaranteeFeeWithoutTax'))} error={!!errors.contract?.monthlyGuaranteeFeeWithoutTax} helperText={errors.contract?.monthlyGuaranteeFeeWithoutTax?.message} disabled={isAgencyUser || isDirectPdUser} />
            </div>
            <div css={rowStyle}>
              <StyledTextField label="その他1" {...registerMui(register('contractAttribution.other1'))} error={!!errors.contractAttribution?.other1} helperText={errors.contractAttribution?.other1?.message} disabled={isAgencyUser || isDirectPdUser} />
              <StyledTextField label="その他1_メモ" {...registerMui(register('contractAttribution.other1Memo'))} error={!!errors.contractAttribution?.other1Memo} helperText={errors.contractAttribution?.other1Memo?.message} disabled={isAgencyUser || isDirectPdUser} />
            </div>
            <div css={rowStyle}>
              <StyledTextField label="その他2" {...registerMui(register('contractAttribution.other2'))} error={!!errors.contractAttribution?.other2} helperText={errors.contractAttribution?.other2?.message} disabled={isAgencyUser || isDirectPdUser} />
              <StyledTextField label="その他2_メモ" {...registerMui(register('contractAttribution.other2Memo'))} error={!!errors.contractAttribution?.other2Memo} helperText={errors.contractAttribution?.other2Memo?.message} disabled={isAgencyUser || isDirectPdUser} />
            </div>
            <div css={rowStyle}>
              <StyledTextField label="その他3" {...registerMui(register('contractAttribution.other3'))} error={!!errors.contractAttribution?.other3} helperText={errors.contractAttribution?.other3?.message} disabled={isAgencyUser || isDirectPdUser} />
              <StyledTextField label="その他3_メモ" {...registerMui(register('contractAttribution.other3Memo'))} error={!!errors.contractAttribution?.other3Memo} helperText={errors.contractAttribution?.other3Memo?.message} disabled={isAgencyUser || isDirectPdUser} />
            </div>
            <div css={modalTitleStyle}>その他費用</div>
            <div css={rowStyle}>
              <StyledTextField type="number" label="更新料(税込)" {...registerMui(register('contract.updateBaseFeeWithTax'))} error={!!errors.contract?.updateBaseFeeWithTax} helperText={errors.contract?.updateBaseFeeWithTax?.message} disabled={isAgencyUser || isDirectPdUser} />
              <StyledTextField type="number" label="更新事務手数料(税込)" {...registerMui(register('contract.updateManagementFeeWithTax'))} error={!!errors.contract?.updateManagementFeeWithTax} helperText={errors.contract?.updateManagementFeeWithTax?.message} disabled={isAgencyUser || isDirectPdUser} />
            </div>
            <div css={rowStyle}>
              <StyledTextField type="number" label="管理更新料(税込)" {...registerMui(register('contract.renewalManagementFeeWithTax'))} error={!!errors.contract?.renewalManagementFeeWithTax} helperText={errors.contract?.renewalManagementFeeWithTax?.message} disabled={isAgencyUser || isDirectPdUser} />
              <StyledTextField type="number" label="更新費用合計(税込)" value={totalRenewalFee} disabled />
            </div>
            <div css={rowStyle}>
              <StyledTextField type="number" label="保管場所使用承諾書発行手数料(税込)" {...registerMui(register('contract.issuePaperFeeWithTax'))} error={!!errors.contract?.issuePaperFeeWithTax} helperText={errors.contract?.issuePaperFeeWithTax?.message} disabled={isAgencyUser || isDirectPdUser} />
              <FormControl css={selectFormStyle} disabled={isAgencyUser || isDirectPdUser}>
                <InputLabel id="contract.seasonalAdjustment" variant="standard" shrink>季節調整</InputLabel>
                <Select label="季節調整" labelId="seasonalAdjustment" variant="standard" {...registerMui(register('contract.seasonalAdjustment'))} error={!!errors.contract?.seasonalAdjustment} defaultValue={defaultValues.contract?.seasonalAdjustment ?? ''}>
                  <MenuItem value=""></MenuItem>
                  {SeasonalAdjustment.map((value, index) => (<MenuItem key={index} value={index}>{value}</MenuItem>))}
                </Select>
              </FormControl>
            </div>
            <div css={rowStyle}>
              <FormControl css={selectFormStyle} disabled={isAgencyUser || isDirectPdUser}>
                <InputLabel id="seasonalRentFrom" variant="standard" shrink>季節賃料FROM</InputLabel>
                <Select label="季節賃料FROM" labelId="seasonalRentFrom" variant="standard" {...registerMui(register('contract.seasonalRentFrom'))} error={!!errors.contract?.seasonalRentFrom} defaultValue={defaultValues.contract?.seasonalRentFrom ?? ''}>
                  <MenuItem value=''></MenuItem>
                  {jpMonthLabels.map((value, index) => <MenuItem key={index} value={1 + index}>{value}</MenuItem>)}
                </Select>
              </FormControl>
              <FormControl css={selectFormStyle} disabled={isAgencyUser || isDirectPdUser}>
                <InputLabel id="seasonalRentTo" variant="standard" shrink>季節賃料TO</InputLabel>
                <Select label="季節賃料TO" labelId="seasonalRentTo" variant="standard" {...registerMui(register('contract.seasonalRentTo'))} error={!!errors.contract?.seasonalRentTo} defaultValue={defaultValues.contract?.seasonalRentTo ?? ''}>
                  <MenuItem value=''></MenuItem>
                  {jpMonthLabels.map((value, index) => <MenuItem key={index} value={1 + index}>{value}</MenuItem>)}
                </Select>
              </FormControl>
            </div>
            <div css={rowStyle}>
              <StyledTextField type="number" label="季節費用(税込)" {...registerMui(register('contract.seasonalFeeWithTax'))} error={!!errors.contract?.seasonalFeeWithTax} helperText={errors.contract?.seasonalFeeWithTax?.message} disabled={isAgencyUser || isDirectPdUser} />
              <StyledTextField type="number" label="日割賃料返金(税込)" {...registerMui(register('contract.proratedRentRefund'))} error={!!errors.contract?.proratedRentRefund} helperText={errors.contract?.proratedRentRefund?.message} disabled={isAgencyUser || isDirectPdUser} />
            </div>
            <div css={rowStyle}>
              <StyledTextField type="number" label="敷金返金(非課税)" {...registerMui(register('contract.securityDepositRefund'))} error={!!errors.contract?.securityDepositRefund} helperText={errors.contract?.securityDepositRefund?.message} disabled={isAgencyUser || isDirectPdUser} />
            </div>
            <div css={modalTitleStyle}>支払い方法</div>
            <div css={rowStyle}>
              <FormControl css={selectFormStyle} disabled={isAgencyUser || isDirectPdUser}>
                <InputLabel id="paymentMethod" variant="standard">支払先</InputLabel>
                <Select label="支払先" labelId="paymentMethod" variant="standard" {...registerMui(register('contract.paymentMethod'))} error={!!errors.contract?.paymentMethod} defaultValue={defaultValues.contract?.paymentMethod ?? ''}>
                  <MenuItem value=""></MenuItem>
                  {paymentMethod.map((value, index) => (<MenuItem key={index} value={index}>{value}</MenuItem>))}
                </Select>
              </FormControl>
              <StyledTextField label="毎月支払日" type="text" {...(contractKind === 'PD' ? { value: 'PD請求' } : {})} {...registerMui(register('contract.paymentDate'))} error={!!errors.contract?.paymentDate} helperText={(errors.contract?.paymentDate as FieldError)?.message} disabled={contractKind === 'PD' || (isAgencyUser || isDirectPdUser)} />
            </div>
            <div css={rowStyle}>
              <StyledTextField label="支払先口座情報" {...registerMui(register('paymentBank'))} error={!!errors.paymentBank} helperText={errors.paymentBank?.message} disabled={isAgencyUser || isDirectPdUser} />
            </div>
          </>
        )}
        {formType === 'init_payment' && (
          <>
            <div css={modalTitleStyle}>初期費用支払い</div>
            <div css={rowStyle}>
              <StyledTextField label="法人名" {...registerMui(register('contractAttribution.initPaymentCompanyName'))} error={!!errors.contractAttribution?.initPaymentCompanyName} helperText={errors.contractAttribution?.initPaymentCompanyName?.message} disabled={isAgencyUser || isDirectPdUser} />
              <StyledTextField type="date" label="送金日"{...registerMui(register('contractAttribution.initPaymentSendMoneyDate'))} error={!!errors.contractAttribution?.initPaymentSendMoneyDate} helperText={(errors.contractAttribution?.initPaymentSendMoneyDate as FieldError)?.message} disabled={isAgencyUser || isDirectPdUser} />
            </div>
            <div css={rowStyle}>
              <StyledTextField label="銀行番号" {...registerMui(register('contractAttribution.initPaymentBankNumber'))} error={!!errors.contractAttribution?.initPaymentBankNumber} helperText={errors.contractAttribution?.initPaymentBankNumber?.message} disabled={isAgencyUser || isDirectPdUser} />
              <StyledTextField label="銀行名" {...registerMui(register('contractAttribution.initPaymentBankName'))} error={!!errors.contractAttribution?.initPaymentBankName} helperText={errors.contractAttribution?.initPaymentBankName?.message} disabled={isAgencyUser || isDirectPdUser} />
            </div>
            <div css={rowStyle}>
              <StyledTextField label="支店番号" {...registerMui(register('contractAttribution.initPaymentBranchStoreNumber'))} error={!!errors.contractAttribution?.initPaymentBranchStoreNumber} helperText={errors.contractAttribution?.initPaymentBranchStoreNumber?.message} disabled={isAgencyUser || isDirectPdUser} />
              <StyledTextField label="支店名" {...registerMui(register('contractAttribution.initPaymentBranchName'))} error={!!errors.contractAttribution?.initPaymentBranchName} helperText={errors.contractAttribution?.initPaymentBranchName?.message} disabled={isAgencyUser || isDirectPdUser} />
            </div>
            <div css={rowStyle}>
              <FormControl css={selectFormStyle} disabled={isAgencyUser || isDirectPdUser}>
                <InputLabel id="initPaymentDepositKindType" variant="standard">預金種目</InputLabel>
                <Select label="預金種目" labelId="initPaymentDepositKindType" variant="standard" {...registerMui(register('contractAttribution.initPaymentDepositKindType'))} error={!!errors.contractAttribution?.initPaymentDepositKindType} defaultValue={defaultValues.contractAttribution?.initPaymentDepositKindType ?? ''}>
                  <MenuItem value=""></MenuItem>
                  {depositKind.map((value) => (<MenuItem key={depositKindType[value]} value={depositKindType[value]}>{value}</MenuItem>))}
                </Select>
              </FormControl>
              <StyledTextField label="口座番号" {...registerMui(register('contractAttribution.initPaymentBankAccountNumber'))} error={!!errors.contractAttribution?.initPaymentBankAccountNumber} helperText={errors.contractAttribution?.initPaymentBankAccountNumber?.message} disabled={isAgencyUser || isDirectPdUser} />
            </div>
            <div css={rowStyle}>
              <StyledTextField label="口座名" {...registerMui(register('contractAttribution.initPaymentBankAccountName'))} error={!!errors.contractAttribution?.initPaymentBankAccountName} helperText={errors.contractAttribution?.initPaymentBankAccountName?.message} disabled={isAgencyUser || isDirectPdUser} />
              <StyledTextField label="送金金額" {...registerMui(register('contractAttribution.initPaymentSendMoneyAmount'))} error={!!errors.contractAttribution?.initPaymentSendMoneyAmount} helperText={errors.contractAttribution?.initPaymentSendMoneyAmount?.message} disabled={isAgencyUser || isDirectPdUser} />
            </div>
          </>
        )}
        {formType === 'monthly_payment' && (
          <>
            <div css={modalTitleStyle}>毎月支払</div>
            <div css={rowStyle}>
              <StyledTextField label="法人名" {...registerMui(register('contractAttribution.monthlyPaymentCompanyName'))} error={!!errors.contractAttribution?.monthlyPaymentCompanyName} helperText={errors.contractAttribution?.monthlyPaymentCompanyName?.message} disabled={isAgencyUser || isDirectPdUser} />
              <StyledTextField type="date" label="送金日" {...registerMui(register('contractAttribution.monthlyPaymentSendMoneyDate'))} error={!!errors.contractAttribution?.monthlyPaymentSendMoneyDate} helperText={(errors.contractAttribution?.monthlyPaymentSendMoneyDate as FieldError)?.message} disabled={isAgencyUser || isDirectPdUser} />
            </div>
            <div css={rowStyle}>
              <StyledTextField label="銀行番号" {...registerMui(register('contractAttribution.monthlyPaymentBankNumber'))} error={!!errors.contractAttribution?.monthlyPaymentBankNumber} helperText={errors.contractAttribution?.monthlyPaymentBankNumber?.message} disabled={isAgencyUser || isDirectPdUser} />
              <StyledTextField label="銀行名" {...registerMui(register('contractAttribution.monthlyPaymentBankName'))} error={!!errors.contractAttribution?.monthlyPaymentBankName} helperText={errors.contractAttribution?.monthlyPaymentBankName?.message} disabled={isAgencyUser || isDirectPdUser} />
            </div>
            <div css={rowStyle}>
              <StyledTextField label="支店番号" {...registerMui(register('contractAttribution.monthlyPaymentBranchStoreNumber'))} error={!!errors.contractAttribution?.monthlyPaymentBranchStoreNumber} helperText={errors.contractAttribution?.monthlyPaymentBranchStoreNumber?.message} disabled={isAgencyUser || isDirectPdUser} />
              <StyledTextField label="支店名" {...registerMui(register('contractAttribution.monthlyPaymentBranchName'))} error={!!errors.contractAttribution?.monthlyPaymentBranchName} helperText={errors.contractAttribution?.monthlyPaymentBranchName?.message} disabled={isAgencyUser || isDirectPdUser} />
            </div>
            <div css={rowStyle}>
              <FormControl css={selectFormStyle} disabled={isAgencyUser || isDirectPdUser}>
                <InputLabel id="monthlyPaymentDepositKindType" variant="standard">預金種目</InputLabel>
                <Select label="預金種目" labelId="monthlyPaymentDepositKindType" variant="standard" {...registerMui(register('contractAttribution.monthlyPaymentDepositKindType'))} error={!!errors.contractAttribution?.monthlyPaymentDepositKindType} defaultValue={defaultValues.contractAttribution?.monthlyPaymentDepositKindType ?? ''}>
                  <MenuItem value=""></MenuItem>
                  {depositKind.map((value) => (<MenuItem key={depositKindType[value]} value={depositKindType[value]}>{value}</MenuItem>))}
                </Select>
              </FormControl>
              <StyledTextField label="口座番号" {...registerMui(register('contractAttribution.monthlyPaymentBankAccountNumber'))} error={!!errors.contractAttribution?.monthlyPaymentBankAccountNumber} helperText={errors.contractAttribution?.monthlyPaymentBankAccountNumber?.message} disabled={isAgencyUser || isDirectPdUser} />
            </div>
            <div css={rowStyle}>
              <StyledTextField label="口座名" {...registerMui(register('contractAttribution.monthlyPaymentBankAccountName'))} error={!!errors.contractAttribution?.monthlyPaymentBankAccountName} helperText={errors.contractAttribution?.monthlyPaymentBankAccountName?.message} disabled={isAgencyUser || isDirectPdUser} />
              <StyledTextField label="送金金額"{...registerMui(register('contractAttribution.monthlyPaymentSendMoneyAmount'))} error={!!errors.contractAttribution?.monthlyPaymentSendMoneyAmount} helperText={errors.contractAttribution?.monthlyPaymentSendMoneyAmount?.message} disabled={isAgencyUser || isDirectPdUser} />
            </div>
          </>
        )}
        <div css={footerButtonsStyle}>
          <LoadingButton variant="contained" onClick={handleSubmit(updateContract)} css={footerButtonStyle} loading={isUploading} disabled={!(isDirty && isValid) || isUploading}>保存</LoadingButton>
          <Button variant="text" onClick={() => {onClose(false); reset(defaultValues);}} css={footerButtonStyle} disabled={isUploading}>キャンセル</Button>
        </div>
      </div>
    </Modal>
  )
}

const LocalizedKeys: { [key: string]: string } = {
  management_state: '契約状態',
  payment_bank: '支払先口座情報',
  normal_contract: '通常契約',
  initial_procedure_type: '初回契約手続き区分',
  contract_condition__contracted_date: '契約日',
  contract_condition__cancel_date: '解約日',
  contract_condition__need_issue_paper: '保管場所使用承諾証明書',
  contract_condition__issue_date: '保管場所使用承諾証明書の発行月',
  contract_condition__contract_period_month: '契約期間',
  contract_condition__first_start_date: '初回契約開始日',
  contract_condition__start_date: '賃貸借期間_開始',
  contract_condition__end_date: '賃貸借期間_終了',
  contract_condition__rent_with_tax: '賃料(税込)',
  contract_condition__rent_without_tax: '賃料（税抜）',
  contract_condition__first_month_rent_with_tax: '初月賃料（税込）',
  contract_condition__final_month_rent_with_tax: '最終月賃料（税込）',
  contract_condition__first_month_rent_without_tax: '初月賃料（税抜）',
  contract_condition__final_month_rent_without_tax: '最終月賃料（税抜）',
  contract_condition__monthly_guarantee_and_management_fee_with_tax: '月額保証／管理料（税込）',
  contract_condition__initial_guarantee_and_management_fee_with_tax: '初回保証／管理料（税込）',
  contract_condition__first_month_guarantee_and_management_fee_with_tax: '初月月額保証/管理料（税込）',
  contract_condition__final_month_guarantee_and_management_fee_with_tax: '最終月月額保証管理料（税込）',
  contract_condition__deposit_with_tax: '敷金（非課税）',
  contract_condition__key_money_with_tax: '礼金（税込）',
  contract_condition__administrative_fee_with_tax: '事務手数料（税込）',
  contract_condition__payment_method: '支払先',
  contract_condition__payment_date: '毎月支払日',
  contract_condition__initial_payment_date: '初回支払日',
  contract_condition__update_base_fee_with_tax: '更新料(税込)',
  contract_condition__update_management_fee_with_tax: '更新事務手数料(税込)',
  contract_condition__renewal_management_fee_with_tax: '管理更新料(税込)',
  contract_condition__issue_paper_fee_with_tax: '保管場所使用承諾証明書発行料（税込）',
  contract_condition__tax_category: '税区分',
  contract_condition__brokerage_fee_with_tax: '仲介手数料(税込)',
  contract_condition__seasonal_adjustment: '季節調整',
  contract_condition__seasonal_rent_from: '季節賃料FROM',
  contract_condition__seasonal_rent_to: '季節賃料TO',
  contract_condition__seasonal_fee_with_tax: '季節費用(税込)',
  contract_condition__prorated_rent_refund: '日割賃料返金(税込)',
  contract_condition__security_deposit_refund: '敷金返金(非課税)',
  parking_user__name: '利用者名',
  parking_user__name_kana: '利用者名(カナ)',
  parking_user__manager: '利用-担当者名',
  parking_user__email: '利用者メールアドレス',
  parking_user__mobile_phone_number: '利用者携帯電話番',
  parking_user__phone_number: '利用者自宅電話番号',
  parking_user__employee_number: '利用者従業員番号',
  parking_user__affiliation_branch_name: '利用者所属拠点名',
  parking_user__affiliation_branch_number: '利用者所属拠点番号',
  parking_user__affiliation_department_name: '利用者所属組織名',
  parking_user__affiliation_department_number: '利用者所属組織番号',
  parking_contractor__name: '契約者名',
  parking_contractor__name_kana: '契約者名(カナ)',
  parking_contractor__manager: '契約-担当者名',
  parking_contractor__email: '契約者メールアドレス',
  parking_contractor__phone_number: '契約者携帯電話番号',
  parking_contractor__company_phone_number: '契約者会社電話番号',
  parking_contractor__employee_number: '契約者従業員番号',
  parking_contractor__affiliation_branch_name: '契約者所属拠点名',
  parking_contractor__affiliation_branch_number: '契約者所属拠点番号',
  parking_contractor__affiliation_department_name: '契約者所属組織名',
  parking_contractor__affiliation_department_number: '契約者所属組織番号',
  client_company__client_company_name: '管理会社名',
  client_company__client_company_postal_code: '管理会社郵便番号',
  client_company__client_store_name: '店舗名',
  client_company__parking_name: '駐車場名',
  client_company__partition: '区画番号',
  client_company__partition_type: '区画種別',
  client_company__address: '駐車場住所',
  client_company__latitude: '経度',
  client_company__longitude: '緯度',
  client_company__partition_image1: '区画図1',
  client_company__partition_image2: '区画図2',
  client_company__partition_image3: '区画図3',
  client_company__invoice_name_or_number: '（管理会社）インボイス発行事業者の氏名または名称および登録番号',
  client_company__invoice_tax_rate: '（管理会社）適用税率',
  client_company__invoice_receiver_name: '（管理会社）書類の交付を受ける事業者の氏名または名称',
  client_company__guarantor_company_name: '保証会社名',
  client_company__guarantor_invoice_number: '保証会社インボイス登録番号',
  contract_vehicle__car_model: '車種',
  contract_vehicle__regno_region_name: '車種-登録番号（地域名）',
  contract_vehicle__regno_class_digit: '車種-登録番号（分類番号3桁）',
  contract_vehicle__regno_hiragana: '車種-登録番号（平仮名等）',
  contract_vehicle__regno_seq_digit: '車種-登録番号(一連指定番号4桁)',
  contract_vehicle__certificate: '車検証',
  contract_attribution__notice_memo1: 'メモ1',
  contract_attribution__notice_memo2: 'メモ2',
  contract_attribution__notice_memo3: 'メモ3',
  contract_attribution__memo1: 'メモ1',
  contract_attribution__memo2: 'メモ2',
  contract_attribution__memo3: 'メモ3',
  contract_attribution__usage: '使用用途',
  contract_attribution__other1: 'その他1',
  contract_attribution__other1_memo: 'その他1_メモ',
  contract_attribution__other2: 'その他2',
  contract_attribution__other2_memo: 'その他2_メモ',
  contract_attribution__other3: 'その他3',
  contract_attribution__other3_memo: 'その他3_メモ',
  lessor__lessor_type: '貸主区分',
  lessor__lessor_postal_code: '貸主郵便番号',
  lessor__invoice_tax_rate: '（貸主）適用税率',
  lessor__invoice_receiver_name: '（貸主）書類の交付を受ける事業者の氏名または名称',
  broker_company__broker_company_postal_code: '仲介会社郵便番号',
  broker_company__invoice_tax_rate: '（仲介会社）適用税率',
  broker_company__invoice_receiver_name: '（仲介会社）書類の交付を受ける事業者の氏名または名称',
} as const;

const showError = (response: any) => {
  alert(convert(response));
};

const convert = (data: any, section?: string): string => {
  let messages = '';

  if (typeof data === 'object') {
    const keys = Object.keys(data);
    keys.forEach(key => {
      if (Array.isArray(data[key])) {
        const localizedKey = LocalizedKeys[(section ? section + '__' : '') + key] ?? key;
        messages += `${localizedKey}\n` + data[key].map((message: string) => `  ・${message}\n`) + "\n";
      } else {
        messages += convert(data[key], key);
      }
    });
  }

  return messages;
};

const jpMonthLabels = Array.from({ length: 12 }, (_, i) => `${i + 1}月`);

const registerMui = (res: UseFormRegisterReturn) => ({
  inputRef: res.ref,
  onChange: res.onChange,
  onBlur: res.onBlur,
  name: res.name,
});

const StyledTextField = styled(TextField) <{ link?: boolean }>`
  width: 49%;
  ${({ link }) => link ? '.MuiInput-input.Mui-disabled { cursor: pointer; -webkit-text-fill-color: #0056B0; }' : ''};
`;
StyledTextField.defaultProps = {
  variant: 'standard',
  InputLabelProps: {
    shrink: true,
  },
};

const StyledFileField = styled(TextField) <{ link?: boolean }>`
  width: 100%;
  ${({ link }) => link ? '.MuiInput-input.Mui-disabled { cursor: pointer; -webkit-text-fill-color: #0056B0; }' : ''};
`;
StyledFileField.defaultProps = {
  variant: 'standard',
  InputLabelProps: {
    shrink: true,
  },
};

const StyledCheckBoxLabel = styled(Typography)(({ disabled }: { disabled?: boolean }) => ({
  fontSize: "13px",
  color: disabled ? "rgba(0, 0, 0, 0.6)" : "#222222",
}));

const StyledCheckBox = styled(Checkbox)({
  width: "18px",
  height: "18px",
  marginLeft: "12px",
  marginRight: "6px",
});

const modalStyle = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const modalContentStyle = css({
  backgroundColor: 'white',
  borderRadius: '10px',
  width: '80vw',
  borderWidth: 0,
  padding: '20px',
  overflow: 'scroll',
  maxHeight: '90vh',
});

const modalTitleStyle = css({
  color: 'black',
  backgroundColor: '#F6F6F6',
  padding: '10px',
  marginBottom: '20px',
  fontSize: '14px',
  fontWeight: 'bold',
});

const rowStyle = css({
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: '20px',
  gap: '0 2%',
});

const footerButtonsStyle = css({
  textAlign: 'center',
});

const footerButtonStyle = css({
  width: '120px',
  marginLeft: '20px',
  marginRight: '20px',
});

const selectFormStyle = css({
  width: '49%',
});

const certificateInputFileStyle = css({
  position: 'absolute',
  marginTop: '1em',
  right: 0,
  width: '30%',
});

const partitionImageInputFileStyle = css({
  position: 'absolute',
  right: '15%',
  bottom: '7px',
  width: '30%',
});

const inputFileColStyle = css({
  flex: 1,
  position: 'relative',
});
