export const ContractKind = ['PD', 'Agency', 'Direct', 'DirectPd'] as const;

export const contractKindList = ["ParkDirect", "代理契約", "直接契約/直接支払い", "直接契約/PD支払い"] as const;

export const contractKindTypeList: { [key: string]: number } = {
  "ParkDirect": 0,
  "代理契約": 1,
  "直接契約/直接支払い": 2,
  "直接契約/PD支払い": 3
} as const;


export const paymentMethod = [
  "Park Direct",
  "振込",
  "口座振替",
  "クレジットカード",
] as const;


export const paymentMethodTypeList: { [key: string]: number } = {
  "Park Direct": 0,
  "振込": 1,
  "口座振替": 2,
  "クレジットカード": 3,
} as const;

export const managementState = ["申し込み", "契約中", "解約済み", "解約申請", "更新完了"] as const;

export const partitionType: { [key: string]: number } = {
  "平置き": 0,
  "機械式": 1,
  "立体": 2,
} as const;

export const partition = [
  "平置き",
  "機械式",
  "立体",
] as const;


// export const sendMoneyCondition = [
//   "送金前",
//   "送金済み",
// ] as const;

// export const sendMoneyConditionType:{[key: string]: number } = {
//   "送金前": 0,
//   "送金済み": 1,
//  } as const;


export const depositKind = [
  "現金",
  "普通",
  "当座",
  "貯蓄",
  "その他",
] as const;

export const depositKindType: { [key: string]: number } = {
  "現金": 0,
  "普通": 1,
  "当座": 2,
  "その他": 3,
  "貯蓄": 4,
} as const;

export const initialProcedureType = [
  "顧客",
  "ニーリー",
] as const;

export const lessorType = [
  "個人",
  "法人",
] as const;

export const taxCategory = [
  "非課税",
  "課税",
] as const;

export const seasonalAdjustment = [
  "冬季賃料",
  "除雪費用",
  "その他",
] as const;
