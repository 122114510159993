import { css, SerializedStyles } from "@emotion/react"
import { faArrowLeft, faCaretLeft, faCaretRight } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Pagination from "rc-pagination"
import "rc-pagination/assets/index.css";
import React, { VFC } from "react"
import { ContractFromApi } from "../../../models/contract/ContractFromApi"
import "./Paginator.css";

const paginationButtonWrapperStyle = css({
  width: "40px",
  height: "40px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

const prevNextButtonWrapperStyle = css({
  width: "40px",
  height: "40px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  transition: "all 0.2s",
  borderRadius: "4px",
  "&:hover": {
    background: "#0056B0"
  }
});

const paginationLinkStyle = css({
  textDecoration: "none",
});

const paginationStyle = css({
  li: {
    width: "40px",
    height: "40px",
    ":first-of-type": {
      marginRight: "1.25rem"
    },
    ":last-of-type": {
      marginLeft: "12px"
    }
  }
});

const pageCommentStyle = css({
  textAlign: "right",
  marginTop: "1rem",
  marginRight: "2rem",
  fontSize: "13px",
  color: "#777777"
});

const itemRender = (current: number, type: "page" | "prev" | "next" | "jump-prev" | "jump-next") => {
  switch (type) {
    case "page":
      return <div css={paginationButtonWrapperStyle}><a css={paginationLinkStyle} href={`#${current}`}>{current}</a></div>
    case "prev":
      return <div css={prevNextButtonWrapperStyle}><a css={paginationLinkStyle} href={`#${current}`}><FontAwesomeIcon color="#777777" icon={faCaretLeft} /></a></div>
    case "next":
      return <div css={prevNextButtonWrapperStyle}><a css={paginationLinkStyle} href={`#${current}`}><FontAwesomeIcon color="#777777" icon={faCaretRight} /></a></div>;
    case "jump-next":
      return <div css={prevNextButtonWrapperStyle}><a css={paginationLinkStyle} href={`#${current}`}>…</a></div>;
    case "jump-prev":
        return <div css={prevNextButtonWrapperStyle}><a css={paginationLinkStyle} href={`#${current}`}>…</a></div>;
  }
}

const calcCurrentPages = (current: number, max: number | undefined) => {
  if (max === undefined) {
    return 0;
  }
  return current > max ? max : current;
}

const calcCurrentPageStart = (current: number, par: number | undefined) => {
  if (par === undefined) {
    return 0;
  }

  if (current === 1) {
    return 1;
  }

  return (current - 1) * par + 1;

}

export const Paginator: VFC<{
  paginationWrapperStyle: SerializedStyles,
  setCurrentPage: (num: number) => void,
  currentPage: number,
  contracts: { count: number, total_pages: number, per_page: number } | undefined

}> = ({
  paginationWrapperStyle,
  setCurrentPage,
  currentPage,
  contracts
}) => {
    return (
      <article>
        <div css={paginationWrapperStyle}>
          <Pagination
            css={paginationStyle}
            onChange={(e) => setCurrentPage(e)}
            current={currentPage}
            total={(contracts?.total_pages ?? 1) * (contracts?.per_page ?? 1)}
            showTitle={false}
            itemRender={itemRender}
          />
        </div>
        <div css={pageCommentStyle}>
          {contracts?.count}件中 {calcCurrentPageStart(currentPage, contracts?.per_page)}〜{calcCurrentPages(currentPage * (contracts?.per_page ?? 1), contracts?.count)}件
        </div>
      </article>
    )
  }