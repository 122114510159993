import { isNotNullOrUndefined } from "./falsyCheck";

/**
 * 指定した値に対応するキーを取得するユーティリティ関数
 * 
 * @template T - オブジェクトの型（キーが文字列である必要があります）
 * @template T2 - オブジェクトの値の型
 * @param {T} object - キーと値のペアからなる対象のオブジェクト
 * @param {T2 | undefined | null} val - 検索対象の値
 * @returns {string | undefined} - 対応するキー（見つからない場合は undefined）
 * 
 * @example
 * const myObject = { a: 1, b: 2, c: 3 };
 * const key = getKeyByValue(myObject, 2); // 'b'
 * 
 * @description
 * この関数は、指定された値 `val` を持つキーをオブジェクト `object` から検索します。
 * 内部では `Object.entries` を使用してキーと値のペアを配列に変換し、
 * 条件に一致するペアを `Array.prototype.find` で検索します。
 * 
 * - 注意点: 値が重複している場合は最初に見つかったキーを返します。
 * - 使用例では特定の値に対応するキーを効率的に見つける場面で役立ちます。
 */
export const getKeyByValue = <T extends { [key: string]: T2 }, T2>(
    object: T,
    val: T2 | undefined | null
): string | undefined => isNotNullOrUndefined(val) ? Object.entries(object)
    .find(([, _val]) => _val === val)?.[0] : undefined


/**
 * 指定されたオブジェクトが空であるかをチェックします。
 *
 * @template T - オブジェクトの型
 * @param {T} obj - チェック対象のオブジェクト
 * @returns {boolean} オブジェクトが空の場合は `true`、それ以外の場合は `false`
 * 
 * @example
 * const emptyObject = {};
 * const nonEmptyObject = { key: 'value' };
 * 
 * console.log(isEmpty(emptyObject)); // true
 * console.log(isEmpty(nonEmptyObject)); // false
 * 
 * @description
 * この関数は、指定されたオブジェクト `obj` が空であるかどうかを確認します。
 * 内部では `Object.keys` を使用してオブジェクトのキーの配列を取得し、
 * その長さが 0 であるかどうかをチェックします。
 * 
 * - 注意点: プロトタイプチェーン上のプロパティは無視されます。
 * - 使用例では、オブジェクトが空であるかどうかを簡単に確認することができます。
 */
export const isEmpty = <T extends object>(obj: T) => {
    return Object.keys(obj).length === 0;
}