import moment from "moment";

/**
 * 日付がundefinedでなければフォーマットした文字列を返す
 * @param {Date | undefined} date フォーマットする日付
 * @param {string} [format="YYYY年MM月DD日"] 使用するフォーマット文字列
 * @returns {string | undefined} フォーマットされた日付文字列またはundefined
 * @returns 
 */
export const dateFormat = (date: Date | null | undefined, format: string = "YYYY年MM月DD日") => date && moment(date).format(format);

 /**
  * 第一引数の文字列か数値がundefinedかnullか空文字の場合空文字を返す。
  * そうでなければ第二引数で受け取った文字列を連結して返す。
  * @param {string | number | null | undefined} data チェックするデータ
  * @param {string} unit 連結する単位
  * @returns {string} 連結された文字列または空文字
  */
 export const withUnitOrNone = (data: string | number | null | undefined, unit: string) => {
   if(data === undefined || data === null || data === "") {
     return "";
   }
   return `${data}${unit}`;
 }
 