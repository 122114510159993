import moment from 'moment';
import * as yup from 'yup';

/**
 * 利用可能な契約種別と権限の組み合わせ。
 * 
 * 利用可能な役割は以下の通り:
 * - `PdAdmin`: PD契約 - 管理者
 * - `PdUser`: PD契約 - ユーザー
 * - `AgencyAdmin`: 代理契約 - 管理者
 * - `AgencyUser`: 代理契約 - ユーザー
 * - `DirectAdmin`: 直接契約/直接支払い - 管理者
 * - `DirectUser`: 直接契約/直接支払い - ユーザー
 * - `DirectPdAdmin`: 直接契約/PD支払い - 管理者
 * - `DirectPdUser`: 直接契約/PD支払い - ユーザー
 */
const roles = ['PdAdmin', 'PdUser', 'AgencyAdmin', 'AgencyUser', 'DirectAdmin', 'DirectUser', 'DirectPdAdmin', 'DirectPdUser'];

/**
 * 空の文字列をnullに変換します。
 *
 * @param value - 元の値が空の文字列でない場合に返される値
 * @param originalValue - チェックされる元の値
 * @returns 元の値が空の文字列でない場合は元の値、それ以外の場合はnull
 * 
 * @example
 * ```typescript
 * transformEmptyStringToNull('foo', 'bar'); // => 'foo'
 * transformEmptyStringToNull('foo', ''); // => null
 * ```
 * 
 * @description
 * 元の値が空の文字列の場合はnullを返します。
 * それ以外の場合は元の値を返します。
 * この関数は、`yup`の`transform`メソッドで使用されます。
 */
const transformEmptyStringToNull = (value: any, originalValue: any) => {
  return typeof originalValue === 'string' && originalValue.trim() === '' ? null : value;
};

/**
 * 日付をフォーマットします。
 *
 * @param value - フォーマットされた日付
 * @param originalValue - チェックされる元の日付
 * @returns フォーマットされた日付
 * 
 * @example
 * ```typescript
 * formatDate('2021-01-01', '2021-01-01'); // => '2021-01-01'
 * formatDate('2021-01-01', ''); // => null
 * ```
 * 
 * @description
 * 元の値が空の文字列の場合はnullを返します。
 * それ以外の場合は、元の値を`moment`を使って`YYYY-MM-DD`形式にフォーマットします。
 * フォーマットできない場合はnullを返します。
 * この関数は、`yup`の`transform`メソッドで使用されます。
 */
const formatDate = (originalValue: any) => {
  const value = moment(originalValue);
  if (!originalValue || !value.isValid()) {
    return '';
  }
  return value.format('YYYY-MM-DD');
};

/**
 * 全フォームのスキーマ。
 */
const formSchemaFull = yup.object({
  managementState: yup.number().transform(transformEmptyStringToNull).nullable(),
  normalContract: yup.boolean(),
  initialProcedureType: yup.number().transform(transformEmptyStringToNull).nullable(),
  autoUpdate: yup.boolean(),
  rentalItem: yup.boolean().nullable(),
  rentalItemContents: yup.string().nullable(),
  paymentBank: yup.string().nullable(),
  user: yup.object({
    name: yup.string().nullable(),
    nameKana: yup.string().nullable(),
    manager: yup.string().nullable(),
    email: yup.string().nullable().email('有効なメールアドレスを入力してください。'),
    mobilePhoneNumber: yup.string().nullable(),
    phoneNumber: yup.string().nullable(),
    employeeNumber: yup.string().nullable(),
    affiliationBranchName: yup.string().nullable(),
    affiliationBranchNumber: yup.string().nullable(),
    affiliationDepartmentName: yup.string().nullable(),
    affiliationDepartmentNumber: yup.string().nullable(),
  }),
  contractor: yup.object({
    name: yup.string().nullable(),
    nameKana: yup.string().nullable(),
    manager: yup.string().nullable(),
    email: yup.string().nullable().email('有効なメールアドレスを入力してください。'),
    phoneNumber: yup.string().nullable(),
    companyPhoneNumber: yup.string().nullable(),
    employeeNumber: yup.string().nullable(),
    affiliationBranchName: yup.string().nullable(),
    affiliationBranchNumber: yup.string().nullable(),
    affiliationDepartmentName: yup.string().nullable(),
    affiliationDepartmentNumber: yup.string().nullable(),
  }),
  clientCompany: yup.object({
    clientCompanyName: yup.string().nullable(),
    clientStoreName: yup.string().nullable(),
    parkingName: yup.string().nullable(),
    partition: yup.string().nullable(),
    partitionType: yup.number().transform(transformEmptyStringToNull).nullable(),
    address: yup.string().nullable(),
    latitude: yup.number().transform(transformEmptyStringToNull).nullable(),
    longitude: yup.number().transform(transformEmptyStringToNull).nullable(),
    partitionImage1: yup.mixed().test(
      'is-array-or-string',
      'partitionImage1 must be an array or a string',
      value => Array.isArray(value) || typeof value === 'string'
    ).nullable().default(''),
    partitionImage2: yup.mixed().test(
      'is-array-or-string',
      'partitionImage2 must be an array or a string',
      value => Array.isArray(value) || typeof value === 'string'
    ).nullable().default(''),
    partitionImage3: yup.mixed().test(
      'is-array-or-string',
      'partitionImage3 must be an array or a string',
      value => Array.isArray(value) || typeof value === 'string'
    ).nullable().default(''),
    clientCompanyPostalCode: yup.string()
            .transform(value => value.replace(/^(\d{3})(\d{1,4})$/, '$1-$2')).matches(/^(?:\d{3}-\d{4})?$/, 'ハイフン付きの正しい郵便番号を入力してください'),
    clientCompanyAddress: yup.string().nullable(),
    phoneNumber: yup.string().nullable(),
    faxNumber: yup.string().nullable(),
    staffName: yup.string().nullable(),
    staffEmail: yup.string().nullable().email('有効なメールアドレスを入力してください。'),
    invoiceNameOrNumber: yup.string().nullable(),
    invoiceTaxRate: yup.number().transform(transformEmptyStringToNull).nullable().typeError('税率は数字で入力してください'),
    invoiceReceiverName: yup.string().nullable(),
    guarantorCompanyName: yup.string().nullable(),
    guarantorInvoiceNumber: yup.string().nullable(),
    note: yup.string().nullable(),
  }),
  lessor: yup.object({
    lessorType: yup.number().transform(transformEmptyStringToNull).nullable(),
    lessorName: yup.string().nullable(),
    postalCode: yup.string()
           .transform(value => value.replace(/^(\d{3})(\d{1,4})$/, '$1-$2')).matches(/^(?:\d{3}-\d{4})?$/, 'ハイフン付きの正しい郵便番号を入力してください'),
    address: yup.string().nullable(),
    phoneNumber: yup.string().nullable(),
    faxNumber: yup.string().nullable(),
    email: yup.string().nullable().email('有効なメールアドレスを入力してください。'),
    invoiceNameOrNumber: yup.string().nullable(),
    invoiceTaxRate: yup.number().transform(transformEmptyStringToNull).nullable().typeError('税率は数字で入力してください'),
    invoiceReceiverName: yup.string().nullable(),
    note: yup.string().nullable(),
  }),
  brokerCompany: yup.object({
    brokerCompanyName: yup.string().nullable(),
    postalCode: yup.string()
            .transform(value => value.replace(/^(\d{3})(\d{1,4})$/, '$1-$2')).matches(/^(?:\d{3}-\d{4})?$/, 'ハイフン付きの正しい郵便番号を入力してください'),
    address: yup.string().nullable(),
    phoneNumber: yup.string().nullable(),
    faxNumber: yup.string().nullable(),
    email: yup.string().nullable().email('有効なメールアドレスを入力してください。'),
    invoiceNameOrNumber: yup.string().nullable(),
    invoiceTaxRate: yup.number().transform(transformEmptyStringToNull).nullable().typeError('税率は数字で入力してください'),
    invoiceReceiverName: yup.string().nullable(),
    note: yup.string().nullable(),
  }),
  carInfo: yup.object({
    carModel: yup.string().nullable(),
    regnoRegionName: yup.string().nullable(),
    regnoClassDigit: yup.string().nullable(),
    regnoHiragana: yup.string().nullable(),
    regnoSeqDigit: yup.string().nullable(),
    certificate: yup.mixed().test(
      'is-array-or-string',
      'certificate must be an array or a string',
      value => Array.isArray(value) || typeof value === 'string'
    ).nullable().default(''),
    certificatePath: yup.string().nullable(),
  }),
  contract: yup.object({
    taxCategory: yup.number().transform(transformEmptyStringToNull).nullable(),
    rentWithTax: yup.number().transform(transformEmptyStringToNull).nullable(),
    rentWithoutTax: yup.number().transform(transformEmptyStringToNull).nullable(),
    firstMonthRentWithTax: yup.number().transform(transformEmptyStringToNull).nullable(),
    finalMonthRentWithTax: yup.number().transform(transformEmptyStringToNull).nullable(),
    firstMonthRentWithoutTax: yup.number().transform(transformEmptyStringToNull).nullable(),
    finalMonthRentWithoutTax: yup.number().transform(transformEmptyStringToNull).nullable(),
    monthlyGuaranteeAndManagementFeeWithTax: yup.number().transform(transformEmptyStringToNull).nullable(),
    initialGuaranteeAndManagementFeeWithTax: yup.number().transform(transformEmptyStringToNull).nullable(),
    firstMonthGuaranteeAndManagementFeeWithTax: yup.number().transform(transformEmptyStringToNull).nullable(),
    finalMonthGuaranteeAndManagementFeeWithTax: yup.number().transform(transformEmptyStringToNull).nullable(),
    updateBaseFeeWithTax: yup.number().transform(transformEmptyStringToNull).nullable(),
    updateManagementFeeWithTax: yup.number().transform(transformEmptyStringToNull).nullable(),
    renewalManagementFeeWithTax: yup.number().transform(transformEmptyStringToNull).nullable(),
    depositWithTax: yup.number().transform(transformEmptyStringToNull).nullable(),
    keyMoneyWithTax: yup.number().transform(transformEmptyStringToNull).nullable(),
    administrativeFeeWithTax: yup.number().transform(transformEmptyStringToNull).nullable(),
    brokerageFeeWithTax: yup.number().transform(transformEmptyStringToNull).nullable(),
    issuePaperFeeWithTax: yup.number().transform(transformEmptyStringToNull).nullable(),
    contractedDate: yup.string().transform(formatDate).nullable().typeError('正しい日付を設定してください'),
    firstStartDate: yup.string().transform(formatDate).nullable().typeError('正しい日付を設定してください'),
    startDate: yup.string().transform(formatDate).nullable().typeError('正しい日付を設定してください'),
    endDate: yup.string().transform(formatDate).nullable().typeError('正しい日付を設定してください'),
    contractPeriodMonth: yup.number().transform(transformEmptyStringToNull).nullable(),
    cancelDate: yup.string().transform(formatDate).nullable().typeError('正しい日付を設定してください'),
    needIssuePaper: yup.boolean(),
    issueDate: yup.string().transform(formatDate).nullable().typeError('正しい日付を設定してください'),
    paymentMethod: yup.number().transform(transformEmptyStringToNull).nullable(),
    paymentDate: yup.string().nullable(),
    initialPaymentDate: yup.string().transform(formatDate).nullable().typeError('正しい日付を設定してください'),
    seasonalAdjustment: yup.number().transform(transformEmptyStringToNull).nullable(),
    seasonalRentFrom: yup.number().transform(transformEmptyStringToNull).nullable(),
    seasonalRentTo: yup.number().transform(transformEmptyStringToNull).nullable(),
    seasonalFeeWithTax: yup.number().transform(transformEmptyStringToNull).nullable(),
    proratedRentRefund: yup.number().transform(transformEmptyStringToNull).nullable(),
    securityDepositRefund: yup.number().transform(transformEmptyStringToNull).nullable(),
    initialGuaranteeFeeWithoutTax: yup.number().transform(transformEmptyStringToNull).nullable(),
    monthlyGuaranteeFeeWithoutTax: yup.number().transform(transformEmptyStringToNull).nullable(),
  }),
  contractAttribution: yup.object({
    other1: yup.string().nullable(),
    other1Memo: yup.string().nullable(),
    other2: yup.string().nullable(),
    other2Memo: yup.string().nullable(),
    other3: yup.string().nullable(),
    other3Memo: yup.string().nullable(),
    noticeMemo1: yup.string().nullable(),
    noticeMemo2: yup.string().nullable(),
    noticeMemo3: yup.string().nullable(),
    memo1: yup.string().nullable(),
    memo2: yup.string().nullable(),
    memo3: yup.string().nullable(),
    usage: yup.string().nullable(),
    initPaymentCompanyName: yup.string().nullable(),
    initPaymentSendMoneyDate: yup.string().transform(formatDate).nullable().typeError('正しい日付を設定してください'),
    initPaymentBankNumber: yup.string().nullable(),
    initPaymentBankName: yup.string().nullable(),
    initPaymentBranchStoreNumber: yup.string().nullable(),
    initPaymentBranchName: yup.string().nullable(),
    initPaymentDepositKindType: yup.number().transform(transformEmptyStringToNull).nullable(),
    initPaymentBankAccountNumber: yup.string().nullable(),
    initPaymentBankAccountName: yup.string().nullable(),
    initPaymentSendMoneyAmount: yup.number().transform(transformEmptyStringToNull).nullable(),
    monthlyPaymentCompanyName: yup.string().nullable(),
    monthlyPaymentSendMoneyDate: yup.string().transform(formatDate).nullable().typeError('正しい日付を設定してください'),
    monthlyPaymentBankNumber: yup.string().nullable(),
    monthlyPaymentBankName: yup.string().nullable(),
    monthlyPaymentBranchStoreNumber: yup.string().nullable(),
    monthlyPaymentBranchName: yup.string().nullable(),
    monthlyPaymentDepositKindType: yup.number().transform(transformEmptyStringToNull).nullable(),
    monthlyPaymentBankAccountNumber: yup.string().nullable(),
    monthlyPaymentBankAccountName: yup.string().nullable(),
    monthlyPaymentSendMoneyAmount: yup.number().transform(transformEmptyStringToNull).nullable(),
  }),
});

/**
 * ロールごとの削除対象フィールドリスト。
 */
const fieldsToRemove: { [key: string]: string[] } = {
  PdAdmin: [
    'managementState',
    'normalContract',
    'autoUpdate',
    'rentalItem',
    'rentalItemContents',
    'paymentBank',
    'user.name',
    'user.nameKana',
    'user.manager',
    'user.email',
    'user.mobilePhoneNumber',
    'user.phoneNumber',
    'contractor.name',
    'contractor.nameKana',
    'contractor.manager',
    'contractor.email',
    'contractor.phoneNumber',
    'contractor.companyPhoneNumber',
    'clientCompany.parkingName',
    'clientCompany.partition',
    'clientCompany.partitionType',
    'clientCompany.address',
    'clientCompany.latitude',
    'clientCompany.longitude',
    'clientCompany.partitionImage1',
    'clientCompany.partitionImage2',
    'clientCompany.partitionImage3',
    'clientCompany.clientCompanyName',
    'clientCompany.clientStoreName',
    'clientCompany.clientCompanyAddress',
    'clientCompany.phoneNumber',
    'clientCompany.faxNumber',
    'clientCompany.staffName',
    'clientCompany.staffEmail',
    'clientCompany.invoiceNameOrNumber',
    'clientCompany.note',
    'lessor.lessorName',
    'lessor.invoiceNameOrNumber',
    'brokerCompany.postalCode',
    'carInfo',
    // 'carInfo.carModel',
    // 'carInfo.regnoRegionName',
    // 'carInfo.regnoClassDigit',
    // 'carInfo.regnoHiragana',
    // 'carInfo.regnoSeqDigit',
    // 'carInfo.certificate',
    'contract.contractedDate',
    'contract.cancelDate',
    'contract.contractPeriodMonth',
    'contract.firstStartDate',
    'contract.startDate',
    'contract.endDate',
    'contract.issueDate',
    'contract.initialPaymentDate',
    'contract.rentWithTax',
    'contract.rentWithoutTax',
    'contract.firstMonthRentWithTax',
    'contract.finalMonthRentWithTax',
    'contract.firstMonthRentWithoutTax',
    'contract.finalMonthRentWithoutTax',
    'contract.monthlyGuaranteeAndManagementFeeWithTax',
    'contract.initialGuaranteeAndManagementFeeWithTax',
    'contract.firstMonthGuaranteeAndManagementFeeWithTax',
    'contract.finalMonthGuaranteeAndManagementFeeWithTax',
    'contract.depositWithTax',
    'contract.keyMoneyWithTax',
    'contract.administrativeFeeWithTax',
    'contract.updateBaseFeeWithTax',
    'contract.updateManagementFeeWithTax',
    'contract.renewalManagementFeeWithTax',
    'contract.issuePaperFeeWithTax',
    'contract.paymentDate',
    'contractAttribution.initPaymentCompanyName',
    'contractAttribution.initPaymentSendMoneyDate',
    'contractAttribution.initPaymentBankNumber',
    'contractAttribution.initPaymentBankName',
    'contractAttribution.initPaymentBranchStoreNumber',
    'contractAttribution.initPaymentBranchName',
    'contractAttribution.initPaymentDepositKindType',
    'contractAttribution.initPaymentBankAccountNumber',
    'contractAttribution.initPaymentBankAccountName',
    'contractAttribution.initPaymentSendMoneyAmount',
    'contractAttribution.monthlyPaymentCompanyName',
    'contractAttribution.monthlyPaymentSendMoneyDate',
    'contractAttribution.monthlyPaymentBankNumber',
    'contractAttribution.monthlyPaymentBankName',
    'contractAttribution.monthlyPaymentBranchStoreNumber',
    'contractAttribution.monthlyPaymentBranchName',
    'contractAttribution.monthlyPaymentDepositKindType',
    'contractAttribution.monthlyPaymentBankAccountNumber',
    'contractAttribution.monthlyPaymentBankAccountName',
    'contractAttribution.monthlyPaymentSendMoneyAmount',
  ],
  PdUser: [
    'managementState',
    'normalContract',
    'initialProcedureType',
    'autoUpdate',
    'rentalItem',
    'rentalItemContents',
    'paymentBank',
    'user.name',
    'user.nameKana',
    'user.manager',
    'user.email',
    'user.mobilePhoneNumber',
    'user.phoneNumber',
    'contractor.name',
    'contractor.nameKana',
    'contractor.manager',
    'contractor.email',
    'contractor.phoneNumber',
    'contractor.companyPhoneNumber',
    'clientCompany',
    // 'clientCompany.parkingName',
    // 'clientCompany.partition',
    // 'clientCompany.partitionType',
    // 'clientCompany.address',
    // 'clientCompany.latitude',
    // 'clientCompany.longitude',
    // 'clientCompany.partitionImage1',
    // 'clientCompany.partitionImage2',
    // 'clientCompany.partitionImage3',
    // 'clientCompany.clientCompanyName',
    // 'clientCompany.clientStoreName',
    // 'clientCompany.clientCompanyPostalCode',
    // 'clientCompany.clientCompanyAddress',
    // 'clientCompany.phoneNumber',
    // 'clientCompany.faxNumber',
    // 'clientCompany.staffName',
    // 'clientCompany.staffEmail',
    // 'clientCompany.invoiceNameOrNumber',
    // 'clientCompany.invoiceTaxRate',
    // 'clientCompany.invoiceReceiverName',
    // 'clientCompany.guarantorCompanyName',
    // 'clientCompany.guarantorInvoiceNumber',
    // 'clientCompany.note',
    'lessor',
    // 'lessor.lessorName',
    // 'lessor.lessorType',
    // 'lessor.postalCode',
    // 'lessor.address',
    // 'lessor.phoneNumber',
    // 'lessor.faxNumber',
    // 'lessor.invoiceNameOrNumber',
    // 'lessor.email',
    // 'lessor.invoiceTaxRate',
    // 'lessor.invoiceReceiverName',
    // 'lessor.note',
    'brokerCompany',
    // 'brokerCompany.brokerCompanyName',
    // 'brokerCompany.postalCode',
    // 'brokerCompany.address',
    // 'brokerCompany.phoneNumber',
    // 'brokerCompany.faxNumber',
    // 'brokerCompany.email',
    // 'brokerCompany.invoiceNameOrNumber',
    // 'brokerCompany.invoiceTaxRate',
    // 'brokerCompany.invoiceReceiverName',
    // 'brokerCompany.note',
    'carInfo',
    // 'carInfo.carModel',
    // 'carInfo.regnoRegionName',
    // 'carInfo.regnoClassDigit',
    // 'carInfo.regnoHiragana',
    // 'carInfo.regnoSeqDigit',
    // 'carInfo.certificate',
    'contract.contractedDate',
    'contract.cancelDate',
    'contract.contractPeriodMonth',
    'contract.firstStartDate',
    'contract.startDate',
    'contract.endDate',
    'contract.issueDate',
    'contract.initialPaymentDate',
    'contract.rentWithTax',
    'contract.rentWithoutTax',
    'contract.firstMonthRentWithTax',
    'contract.finalMonthRentWithTax',
    'contract.firstMonthRentWithoutTax',
    'contract.finalMonthRentWithoutTax',
    'contract.monthlyGuaranteeAndManagementFeeWithTax',
    'contract.initialGuaranteeAndManagementFeeWithTax',
    'contract.firstMonthGuaranteeAndManagementFeeWithTax',
    'contract.finalMonthGuaranteeAndManagementFeeWithTax',
    'contract.depositWithTax',
    'contract.keyMoneyWithTax',
    'contract.administrativeFeeWithTax',
    'contract.brokerageFeeWithTax',
    'contract.initialGuaranteeFeeWithoutTax',
    'contract.monthlyGuaranteeFeeWithoutTax',
    'contract.updateBaseFeeWithTax',
    'contract.updateManagementFeeWithTax',
    'contract.renewalManagementFeeWithTax',
    'contract.issuePaperFeeWithTax',
    'contract.seasonalAdjustment',
    'contract.seasonalRentFrom',
    'contract.seasonalRentTo',
    'contract.seasonalFeeWithTax',
    'contract.proratedRentRefund',
    'contract.securityDepositRefund',
    'contract.paymentMethod',
    'contract.paymentDate',
    'contractAttribution.noticeMemo1',
    'contractAttribution.noticeMemo2',
    'contractAttribution.noticeMemo3',
    'contractAttribution.other1',
    'contractAttribution.other1Memo',
    'contractAttribution.other2',
    'contractAttribution.other2Memo',
    'contractAttribution.other3',
    'contractAttribution.other3Memo',
    'contractAttribution.initPaymentCompanyName',
    'contractAttribution.initPaymentSendMoneyDate',
    'contractAttribution.initPaymentBankNumber',
    'contractAttribution.initPaymentBankName',
    'contractAttribution.initPaymentBranchStoreNumber',
    'contractAttribution.initPaymentBranchName',
    'contractAttribution.initPaymentDepositKindType',
    'contractAttribution.initPaymentBankAccountNumber',
    'contractAttribution.initPaymentBankAccountName',
    'contractAttribution.initPaymentSendMoneyAmount',
    'contractAttribution.monthlyPaymentCompanyName',
    'contractAttribution.monthlyPaymentSendMoneyDate',
    'contractAttribution.monthlyPaymentBankNumber',
    'contractAttribution.monthlyPaymentBankName',
    'contractAttribution.monthlyPaymentBranchStoreNumber',
    'contractAttribution.monthlyPaymentBranchName',
    'contractAttribution.monthlyPaymentDepositKindType',
    'contractAttribution.monthlyPaymentBankAccountNumber',
    'contractAttribution.monthlyPaymentBankAccountName',
    'contractAttribution.monthlyPaymentSendMoneyAmount',
  ],
  // AgencyUser: [
  //   'managementState',
  //   'normalContract',
  //   'initialProcedureType',
  //   'autoUpdate',
  //   'rentalItem',
  //   'rentalItemContents',
  //   'paymentBank',
  //   'user.name',
  //   'user.nameKana',
  //   'user.manager',
  //   'user.email',
  //   'user.mobilePhoneNumber',
  //   'user.phoneNumber',
  //   'contractor.name',
  //   'contractor.nameKana',
  //   'contractor.manager',
  //   'contractor.email',
  //   'contractor.phoneNumber',
  //   'contractor.companyPhoneNumber',
  //   'clientCompany',
  //   // 'clientCompany.parkingName',
  //   // 'clientCompany.partition',
  //   // 'clientCompany.partitionType',
  //   // 'clientCompany.address',
  //   // 'clientCompany.latitude',
  //   // 'clientCompany.longitude',
  //   // 'clientCompany.partitionImage1',
  //   // 'clientCompany.partitionImage2',
  //   // 'clientCompany.partitionImage3',
  //   // 'clientCompany.clientCompanyName',
  //   // 'clientCompany.clientStoreName',
  //   // 'clientCompany.clientCompanyPostalCode',
  //   // 'clientCompany.clientCompanyAddress',
  //   // 'clientCompany.phoneNumber',
  //   // 'clientCompany.faxNumber',
  //   // 'clientCompany.staffName',
  //   // 'clientCompany.staffEmail',
  //   // 'clientCompany.invoiceNameOrNumber',
  //   // 'clientCompany.invoiceTaxRate',
  //   // 'clientCompany.invoiceReceiverName',
  //   // 'clientCompany.guarantorCompanyName',
  //   // 'clientCompany.guarantorInvoiceNumber',
  //   // 'clientCompany.note',
  //   'lessor',
  //   // 'lessor.lessorName',
  //   // 'lessor.lessorType',
  //   // 'lessor.postalCode',
  //   // 'lessor.address',
  //   // 'lessor.phoneNumber',
  //   // 'lessor.faxNumber',
  //   // 'lessor.invoiceNameOrNumber',
  //   // 'lessor.email',
  //   // 'lessor.invoiceTaxRate',
  //   // 'lessor.invoiceReceiverName',
  //   // 'lessor.note',
  //   'brokerCompany',
  //   // 'brokerCompany.brokerCompanyName',
  //   // 'brokerCompany.postalCode',
  //   // 'brokerCompany.address',
  //   // 'brokerCompany.phoneNumber',
  //   // 'brokerCompany.faxNumber',
  //   // 'brokerCompany.email',
  //   // 'brokerCompany.invoiceNameOrNumber',
  //   // 'brokerCompany.invoiceTaxRate',
  //   // 'brokerCompany.invoiceReceiverName',
  //   // 'brokerCompany.note',
  //   'carInfo',
  //   // 'carInfo.carModel',
  //   // 'carInfo.regnoRegionName',
  //   // 'carInfo.regnoClassDigit',
  //   // 'carInfo.regnoHiragana',
  //   // 'carInfo.regnoSeqDigit',
  //   // 'carInfo.certificate',
  //   'contract',
  //   // 'contract.contractedDate',
  //   // 'contract.cancelDate',
  //   // 'contract.contractPeriodMonth',
  //   // 'contract.firstStartDate',
  //   // 'contract.startDate',
  //   // 'contract.endDate',
  //   // 'contract.issueDate',
  //   // 'contract.initialPaymentDate',
  //   // 'contract.taxCategory',
  //   // 'contract.rentWithTax',
  //   // 'contract.rentWithoutTax',
  //   // 'contract.firstMonthRentWithTax',
  //   // 'contract.finalMonthRentWithTax',
  //   // 'contract.firstMonthRentWithoutTax',
  //   // 'contract.finalMonthRentWithoutTax',
  //   // 'contract.monthlyGuaranteeAndManagementFeeWithTax',
  //   // 'contract.initialGuaranteeAndManagementFeeWithTax',
  //   // 'contract.firstMonthGuaranteeAndManagementFeeWithTax',
  //   // 'contract.finalMonthGuaranteeAndManagementFeeWithTax',
  //   // 'contract.depositWithTax',
  //   // 'contract.keyMoneyWithTax',
  //   // 'contract.administrativeFeeWithTax',
  //   // 'contract.brokerageFeeWithTax',
  //   // 'contract.initialGuaranteeFeeWithoutTax',
  //   // 'contract.monthlyGuaranteeFeeWithoutTax',
  //   // 'contract.updateBaseFeeWithTax',
  //   // 'contract.updateManagementFeeWithTax',
  //   // 'contract.renewalManagementFeeWithTax',
  //   // 'contract.issuePaperFeeWithTax',
  //   // 'contract.seasonalAdjustment',
  //   // 'contract.seasonalRentFrom',
  //   // 'contract.seasonalRentTo',
  //   // 'contract.seasonalFeeWithTax',
  //   // 'contract.proratedRentRefund',
  //   // 'contract.securityDepositRefund',
  //   // 'contract.paymentMethod',
  //   // 'contract.paymentDate',
  //   'contractAttribution.noticeMemo1',
  //   'contractAttribution.noticeMemo2',
  //   'contractAttribution.noticeMemo3',
  //   'contractAttribution.other1',
  //   'contractAttribution.other1Memo',
  //   'contractAttribution.other2',
  //   'contractAttribution.other2Memo',
  //   'contractAttribution.other3',
  //   'contractAttribution.other3Memo',
  //   'contractAttribution.initPaymentCompanyName',
  //   'contractAttribution.initPaymentSendMoneyDate',
  //   'contractAttribution.initPaymentBankNumber',
  //   'contractAttribution.initPaymentBankName',
  //   'contractAttribution.initPaymentBranchStoreNumber',
  //   'contractAttribution.initPaymentBranchName',
  //   'contractAttribution.initPaymentDepositKindType',
  //   'contractAttribution.initPaymentBankAccountNumber',
  //   'contractAttribution.initPaymentBankAccountName',
  //   'contractAttribution.initPaymentSendMoneyAmount',
  //   'contractAttribution.monthlyPaymentCompanyName',
  //   'contractAttribution.monthlyPaymentSendMoneyDate',
  //   'contractAttribution.monthlyPaymentBankNumber',
  //   'contractAttribution.monthlyPaymentBankName',
  //   'contractAttribution.monthlyPaymentBranchStoreNumber',
  //   'contractAttribution.monthlyPaymentBranchName',
  //   'contractAttribution.monthlyPaymentDepositKindType',
  //   'contractAttribution.monthlyPaymentBankAccountNumber',
  //   'contractAttribution.monthlyPaymentBankAccountName',
  //   'contractAttribution.monthlyPaymentSendMoneyAmount',
  // ],
  // DirectUser: [
  //   'normalContract',
  //   'contractAttribution.noticeMemo1',
  //   'contractAttribution.noticeMemo2',
  //   'contractAttribution.noticeMemo3',
  // ],
  // DirectPdUser: [
  //   'managementState',
  //   'normalContract',
  //   'initialProcedureType',
  //   'autoUpdate',
  //   'rentalItem',
  //   'rentalItemContents',
  //   'paymentBank',
  //   'contractor.name',
  //   'contractor.nameKana',
  //   'contractor.manager',
  //   'contractor.email',
  //   'contractor.phoneNumber',
  //   'contractor.companyPhoneNumber',
  //   'clientCompany',
  //   // 'clientCompany.parkingName',
  //   // 'clientCompany.partition',
  //   // 'clientCompany.partitionType',
  //   // 'clientCompany.address',
  //   // 'clientCompany.latitude',
  //   // 'clientCompany.longitude',
  //   // 'clientCompany.partitionImage1',
  //   // 'clientCompany.partitionImage2',
  //   // 'clientCompany.partitionImage3',
  //   // 'clientCompany.clientCompanyName',
  //   // 'clientCompany.clientStoreName',
  //   // 'clientCompany.clientCompanyPostalCode',
  //   // 'clientCompany.clientCompanyAddress',
  //   // 'clientCompany.phoneNumber',
  //   // 'clientCompany.faxNumber',
  //   // 'clientCompany.staffName',
  //   // 'clientCompany.staffEmail',
  //   // 'clientCompany.invoiceNameOrNumber',
  //   // 'clientCompany.invoiceTaxRate',
  //   // 'clientCompany.invoiceReceiverName',
  //   // 'clientCompany.guarantorCompanyName',
  //   // 'clientCompany.guarantorInvoiceNumber',
  //   // 'clientCompany.note',
  //   'lessor',
  //   // 'lessor.lessorName',
  //   // 'lessor.lessorType',
  //   // 'lessor.postalCode',
  //   // 'lessor.address',
  //   // 'lessor.phoneNumber',
  //   // 'lessor.faxNumber',
  //   // 'lessor.invoiceNameOrNumber',
  //   // 'lessor.email',
  //   // 'lessor.invoiceTaxRate',
  //   // 'lessor.invoiceReceiverName',
  //   // 'lessor.note',
  //   'brokerCompany',
  //   // 'brokerCompany.brokerCompanyName',
  //   // 'brokerCompany.postalCode',
  //   // 'brokerCompany.address',
  //   // 'brokerCompany.phoneNumber',
  //   // 'brokerCompany.faxNumber',
  //   // 'brokerCompany.email',
  //   // 'brokerCompany.invoiceNameOrNumber',
  //   // 'brokerCompany.invoiceTaxRate',
  //   // 'brokerCompany.invoiceReceiverName',
  //   // 'brokerCompany.note',
  //   'contract.contractedDate',
  //   'contract.cancelDate',
  //   'contract.contractPeriodMonth',
  //   'contract.firstStartDate',
  //   'contract.startDate',
  //   'contract.endDate',
  //   'contract.initialPaymentDate',
  //   'contract.taxCategory',
  //   'contract.rentWithTax',
  //   'contract.rentWithoutTax',
  //   'contract.firstMonthRentWithTax',
  //   'contract.finalMonthRentWithTax',
  //   'contract.firstMonthRentWithoutTax',
  //   'contract.finalMonthRentWithoutTax',
  //   'contract.monthlyGuaranteeAndManagementFeeWithTax',
  //   'contract.initialGuaranteeAndManagementFeeWithTax',
  //   'contract.firstMonthGuaranteeAndManagementFeeWithTax',
  //   'contract.finalMonthGuaranteeAndManagementFeeWithTax',
  //   'contract.depositWithTax',
  //   'contract.keyMoneyWithTax',
  //   'contract.administrativeFeeWithTax',
  //   'contract.brokerageFeeWithTax',
  //   'contract.initialGuaranteeFeeWithoutTax',
  //   'contract.monthlyGuaranteeFeeWithoutTax',
  //   'contract.updateBaseFeeWithTax',
  //   'contract.updateManagementFeeWithTax',
  //   'contract.renewalManagementFeeWithTax',
  //   'contract.issuePaperFeeWithTax',
  //   'contract.seasonalAdjustment',
  //   'contract.seasonalRentFrom',
  //   'contract.seasonalRentTo',
  //   'contract.seasonalFeeWithTax',
  //   'contract.proratedRentRefund',
  //   'contract.securityDepositRefund',
  //   'contract.paymentMethod',
  //   'contract.paymentDate',
  //   'contractAttribution.noticeMemo1',
  //   'contractAttribution.noticeMemo2',
  //   'contractAttribution.noticeMemo3',
  //   'contractAttribution.other1',
  //   'contractAttribution.other1Memo',
  //   'contractAttribution.other2',
  //   'contractAttribution.other2Memo',
  //   'contractAttribution.other3',
  //   'contractAttribution.other3Memo',
  //   'contractAttribution.initPaymentCompanyName',
  //   'contractAttribution.initPaymentSendMoneyDate',
  //   'contractAttribution.initPaymentBankNumber',
  //   'contractAttribution.initPaymentBankName',
  //   'contractAttribution.initPaymentBranchStoreNumber',
  //   'contractAttribution.initPaymentBranchName',
  //   'contractAttribution.initPaymentDepositKindType',
  //   'contractAttribution.initPaymentBankAccountNumber',
  //   'contractAttribution.initPaymentBankAccountName',
  //   'contractAttribution.initPaymentSendMoneyAmount',
  //   'contractAttribution.monthlyPaymentCompanyName',
  //   'contractAttribution.monthlyPaymentSendMoneyDate',
  //   'contractAttribution.monthlyPaymentBankNumber',
  //   'contractAttribution.monthlyPaymentBankName',
  //   'contractAttribution.monthlyPaymentBranchStoreNumber',
  //   'contractAttribution.monthlyPaymentBranchName',
  //   'contractAttribution.monthlyPaymentDepositKindType',
  //   'contractAttribution.monthlyPaymentBankAccountNumber',
  //   'contractAttribution.monthlyPaymentBankAccountName',
  //   'contractAttribution.monthlyPaymentSendMoneyAmount',
  // ],
};

/**
 * 指定されたフィールドをスキーマから削除します。
 *
 * @param schema - フィールドを削除する元のスキーマ
 * @param fields - 削除するフィールドのリスト。ネストされたフィールドはドットで区切って指定します。
 * @returns フィールドが削除された新しいスキーマ
 */
function removeFields(schema: any, fields: string[]) {
  let newSchema = schema.clone();

  fields.forEach(field => {
    const keys = field.split('.');
    if (keys.length === 1) {
      newSchema = newSchema.omit([keys[0]]);
    } else {
      const [firstKey, ...restKeys] = keys;
      if (newSchema.fields[firstKey] && newSchema.fields[firstKey].fields) {
        newSchema = newSchema.shape({
          ...newSchema.fields,
          [firstKey]: removeFields(yup.object(newSchema.fields[firstKey].fields), [restKeys.join('.')])
        });
      }
    }
  });

  return newSchema;
};

/**
 * 指定された契約種別と権限に対応するフォームスキーマを取得します。
 *
 * @param contractKind - 契約種別
 * @param isAdmin - 管理者かどうか
 * @returns フォームスキーマ
 */
export function getFormSchema(contractKind: number, isAdmin: boolean = false): yup.ObjectSchema<any> {
  // 該当のロールがない場合は、最低限の項目を有するロールを返す
  const role = roles.find((_, index) => contractKind === Math.floor(index / 2) && isAdmin === (index % 2 === 0)) ?? roles[3];
  return fieldsToRemove[role] ? removeFields(formSchemaFull, fieldsToRemove[role]) : formSchemaFull;
};

/**
 * フォームスキーマの型。
 */
export type FormData = yup.InferType<typeof formSchemaFull>;

/**
 * フォームデータを取得します。
 *
 * @param formSchema - フォームスキーマ
 * @param data - フォームデータ
 * @returns フォームデータ
 */
export const toFormData = <T>(data: any, schema: any): T => {
  const filteredData: any = {};
  Object.keys(schema.fields).forEach(key => {
    if (data[key] !== undefined) {
      if (typeof schema.fields[key] === 'object' && schema.fields[key].fields) {
        filteredData[key] = toFormData(data[key], schema.fields[key]);
      } else {
        filteredData[key] = data[key];
      }
    }
  });
  return filteredData as T;
};

/**
 * 2つのフォームデータを比較して、差分のフォームデータを返却します。
 *
 * @param src - 比較元のデータ
 * @param dst - 比較対象のデータ
 * @returns 差分のフォームデータ
 */
export function diffFormData(src: any, dst: any) {
  const diffData: any = {};

  Object.keys(src).forEach(key => {
    const srcVal = typeof src[key] === 'number' ? src[key].toString() : src[key];
    const dstVal = typeof dst[key] === 'number' ? dst[key].toString() : dst[key];

    if (typeof srcVal === 'object' && srcVal !== null && !Array.isArray(srcVal)) {
      const nestedDiff = diffFormData(srcVal, dstVal || {});
      if (Object.keys(nestedDiff).length > 0) {
        diffData[key] = nestedDiff;
      }
    } else if (srcVal !== dstVal) {
      diffData[key] = dstVal;
    }
  });

  return diffData;
}
