import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Button, FormControl, InputLabel, MenuItem, Modal, Select, TextField } from "@mui/material";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useForm, UseFormRegisterReturn } from "react-hook-form";
import { initialProcedureType, lessorType, paymentMethod, seasonalAdjustment, taxCategory } from "../../../constant";
import { api } from "../../../infra/Api";
import { HttpStatus } from "../../../models/HttpStatus";
import { ContractDetail } from "../../../models/contract/ContractDetail";
import { diffFormData, FormData, getFormSchema, toFormData } from "../../../models/contract/ContractForm";
import { convertFromContractDetail } from "../../../models/contract/ContractToApi";

export type PDContractDetailEditModalProps = {
  isVisible: boolean,
  onClose: (isUpdated: boolean) => void,
  contractDetail: ContractDetail,
  isAdmin?: boolean,
};

export const PDContractDetailEditModal = ({
  isVisible,
  onClose,
  contractDetail,
  isAdmin,
}: PDContractDetailEditModalProps) => {
  const isMounted = useRef(true);

  const [isUploading, setIsUploading] = useState(false);
  const formSchema = useMemo(() => getFormSchema(contractDetail.contractKind, isAdmin), [contractDetail, isAdmin]);
  const defaultValues = useMemo(() => toFormData<FormData>(contractDetail, formSchema), [contractDetail, formSchema]);
  
  const { formState: { errors, isDirty, isValid }, handleSubmit, register, reset } = useForm({
    resolver: yupResolver(formSchema),
    mode: 'onTouched',
    defaultValues,
  });
  
  const registerMui = (res: UseFormRegisterReturn) => ({
    inputRef: res.ref,
    onChange: res.onChange,
    onBlur: res.onBlur,
    name: res.name,
  })
  
  const updateContract = useCallback(async (formData) => {
    try {
      setIsUploading(true);

      const diff = diffFormData(defaultValues, formData);
      if (Object.keys(diff).length === 0) return onClose(false);

      const param = convertFromContractDetail(diff);
      const [res, response] = await api.patch(
        isAdmin
          ? `/api/v1/admin/contract/${contractDetail.id}/`
          : `/api/v1/contract/${contractDetail.id}/`
        , param);

      if (isMounted.current) {
        if (res.status === HttpStatus.OK) {
          onClose(true);
        } else {
          showError(response);
        }
      }
    } finally {
      if (isMounted.current) {
        setIsUploading(false);
      }
    }
  }, [defaultValues, isAdmin, onClose, contractDetail]);

  useEffect(() => {
    isMounted.current = true;
    return () => { isMounted.current = false; };
  }, []);

  useEffect(() => {
    if (isMounted.current) {
      reset(defaultValues);
    }
  }, [defaultValues, reset]);

  return (
    <Modal open={isVisible} css={modalStyle}>
      <div css={modalContentStyle}>
        {isAdmin && (
          <>
            <div css={modalTitleStyle}>基本情報</div>
            <div css={rowStyle}>
              <FormControl css={selectFormStyle}>
              <InputLabel id="initialProcedureType" variant="standard" shrink>初回契約手続き区分</InputLabel>
              <Select label="初回契約手続き区分" labelId="initialProcedureType" variant="standard" {...registerMui(register('initialProcedureType'))} defaultValue={defaultValues.initialProcedureType ?? ''}>
                <MenuItem value=""></MenuItem>
                {initialProcedureType.map((value, index) => (<MenuItem key={index} value={index}>{value}</MenuItem>))}
              </Select>
              </FormControl>
            </div>
          </>
        )}

        <div css={modalTitleStyle}>利用者情報</div>
        <div css={rowStyle}>
          <StyledTextField label="利用者従業員番号" {...registerMui(register('user.employeeNumber'))} error={!!errors.user?.employeeNumber} helperText={errors.user?.employeeNumber?.message} />
        </div>
        <div css={rowStyle}>
          <StyledTextField label="利用者所属拠点名" {...registerMui(register('user.affiliationBranchName'))} error={!!errors.user?.affiliationBranchName} helperText={errors.user?.affiliationBranchName?.message} />
          <StyledTextField label="利用者所属拠点番号" {...registerMui(register('user.affiliationBranchNumber'))} error={!!errors.user?.affiliationBranchNumber} helperText={errors.user?.affiliationBranchNumber?.message} />
        </div>
        <div css={rowStyle}>
          <StyledTextField label="利用者所属組織名" {...registerMui(register('user.affiliationDepartmentName'))} error={!!errors.user?.affiliationDepartmentName} helperText={errors.user?.affiliationDepartmentName?.message} />
          <StyledTextField label="利用者所属組織番号" {...registerMui(register('user.affiliationDepartmentNumber'))} error={!!errors.user?.affiliationDepartmentNumber} helperText={errors.user?.affiliationDepartmentNumber?.message} />
        </div>

        <div css={modalTitleStyle}>契約者情報</div>
        <div css={rowStyle}>
          <StyledTextField label="契約者従業員番号" {...registerMui(register('contractor.employeeNumber'))} error={!!errors.contractor?.employeeNumber} helperText={errors.contractor?.employeeNumber?.message} />
        </div>
        <div css={rowStyle}>
          <StyledTextField label="契約者所属拠点名" {...registerMui(register('contractor.affiliationBranchName'))} error={!!errors.contractor?.affiliationBranchName} helperText={errors.contractor?.affiliationBranchName?.message} />
          <StyledTextField label="契約者所属拠点番号" {...registerMui(register('contractor.affiliationBranchNumber'))} error={!!errors.contractor?.affiliationBranchNumber} helperText={errors.contractor?.affiliationBranchNumber?.message} />
        </div>
        <div css={rowStyle}>
          <StyledTextField label="契約者所属組織名" {...registerMui(register('contractor.affiliationDepartmentName'))} error={!!errors.contractor?.affiliationDepartmentName} helperText={errors.contractor?.affiliationDepartmentName?.message} />
          <StyledTextField label="契約者所属組織番号" {...registerMui(register('contractor.affiliationDepartmentNumber'))} error={!!errors.contractor?.affiliationDepartmentNumber} helperText={errors.contractor?.affiliationDepartmentNumber?.message} />
        </div>

        {isAdmin && (
          <>
            <div css={modalTitleStyle}>管理会社情報</div>
            <div css={rowStyle}>
              <StyledTextField label="管理会社郵便番号" {...registerMui(register('clientCompany.clientCompanyPostalCode'))} error={!!errors.clientCompany?.clientCompanyPostalCode} helperText={errors.clientCompany?.clientCompanyPostalCode?.message} />
              <StyledTextField label="適用税率" {...registerMui(register('clientCompany.invoiceTaxRate'))} type="number" error={!!errors.clientCompany?.invoiceTaxRate} helperText={errors.clientCompany?.invoiceTaxRate?.message} />
            </div>
            <div css={rowStyle}>
              <StyledTextField label="書類の交付を受ける事業者の氏名または名称" {...registerMui(register('clientCompany.invoiceReceiverName'))} error={!!errors.clientCompany?.invoiceReceiverName} helperText={errors.clientCompany?.invoiceReceiverName?.message} />
            </div>
            <div css={rowStyle}>
              <StyledTextField label="保証会社名" {...registerMui(register('clientCompany.guarantorCompanyName'))} error={!!errors.clientCompany?.guarantorCompanyName} helperText={errors.clientCompany?.guarantorCompanyName?.message} />
              <StyledTextField label="保証会社インボイス登録番号" {...registerMui(register('clientCompany.guarantorInvoiceNumber'))} error={!!errors.clientCompany?.guarantorInvoiceNumber} helperText={errors.clientCompany?.guarantorInvoiceNumber?.message} />
            </div>
          </>
        )}

        {isAdmin && (
          <>
            <div css={modalTitleStyle}>貸主情報</div>
            <div css={rowStyle}>
              <div></div>
              <FormControl css={selectFormStyle}>
              <InputLabel id="lessorType" variant="standard" shrink>貸主区分</InputLabel>
              <Select label="貸主区分" labelId="lessorType" variant="standard" {...registerMui(register('lessor.lessorType'))} error={!!errors.lessor?.lessorType} defaultValue={defaultValues.lessor.lessorType ?? ''}>
                <MenuItem value=""></MenuItem>
                {lessorType.map((value, index) => (<MenuItem key={index} value={index}>{value}</MenuItem>))}
              </Select>
              </FormControl>
            </div>
            <div css={rowStyle}>
              <StyledTextField label="貸主郵便番号" {...registerMui(register('lessor.postalCode'))} error={!!errors.lessor?.postalCode} helperText={errors.lessor?.postalCode?.message} />
              <StyledTextField label="貸主住所" {...registerMui(register('lessor.address'))} error={!!errors.lessor?.address} helperText={errors.lessor?.address?.message} />
            </div>
            <div css={rowStyle}>
              <StyledTextField label="貸主電話番号" {...registerMui(register('lessor.phoneNumber'))} error={!!errors.lessor?.phoneNumber} helperText={errors.lessor?.phoneNumber?.message} />
              <StyledTextField label="貸主FAX番号" {...registerMui(register('lessor.faxNumber'))} error={!!errors.lessor?.faxNumber} helperText={errors.lessor?.faxNumber?.message} />
            </div>
            <div css={rowStyle}>
              <StyledTextField label="貸主メールアドレス" {...registerMui(register('lessor.email'))} error={!!errors.lessor?.email} helperText={errors.lessor?.email?.message} />
            </div>
            <div css={rowStyle}>
              <div></div>
              <StyledTextField type="number" label="適用税率" {...registerMui(register('lessor.invoiceTaxRate'))} error={!!errors.lessor?.invoiceTaxRate} helperText={errors.lessor?.invoiceTaxRate?.message} />
            </div>
            <div css={rowStyle}>
              <StyledTextField label="書類の交付を受ける事業者の氏名または名称" {...registerMui(register('lessor.invoiceReceiverName'))} error={!!errors.lessor?.invoiceReceiverName} helperText={errors.lessor?.invoiceReceiverName?.message} />
            </div>
            <div css={rowStyle}>
              <StyledTextField label="備考" {...registerMui(register('lessor.note'))} error={!!errors.lessor?.note} helperText={errors.lessor?.note?.message} />
            </div>
          </>
        )}

        {isAdmin && (
          <>
            <div css={modalTitleStyle}>仲介会社情報</div>
            <div css={rowStyle}>
              <StyledTextField label="仲介会社名" {...registerMui(register('brokerCompany.brokerCompanyName'))} error={!!errors.brokerCompany?.brokerCompanyName} helperText={errors.brokerCompany?.brokerCompanyName?.message} />
            </div>
            <div css={rowStyle}>
              <div></div>
              <StyledTextField label="仲介会社住所" {...registerMui(register('brokerCompany.address'))} error={!!errors.brokerCompany?.address} helperText={errors.brokerCompany?.address?.message} />
            </div>
            <div css={rowStyle}>
              <StyledTextField label="仲介会社電話番号" {...registerMui(register('brokerCompany.phoneNumber'))} error={!!errors.brokerCompany?.phoneNumber} helperText={errors.brokerCompany?.phoneNumber?.message} />
              <StyledTextField label="仲介会社FAX番号" {...registerMui(register('brokerCompany.faxNumber'))} error={!!errors.brokerCompany?.faxNumber} helperText={errors.brokerCompany?.faxNumber?.message} />
            </div>
            <div css={rowStyle}>
              <StyledTextField label="仲介会社メールアドレス" {...registerMui(register('brokerCompany.email'))} error={!!errors.brokerCompany?.email} helperText={errors.brokerCompany?.email?.message} />
              <StyledTextField label="インボイス発行事業者の氏名または名称および登録番号" {...registerMui(register('brokerCompany.invoiceNameOrNumber'))} error={!!errors.brokerCompany?.invoiceNameOrNumber} helperText={errors.brokerCompany?.invoiceNameOrNumber?.message} />
            </div>
            <div css={rowStyle}>
              <StyledTextField type="number" label="適用税率" {...registerMui(register('brokerCompany.invoiceTaxRate'))} error={!!errors.brokerCompany?.invoiceTaxRate} helperText={errors.brokerCompany?.invoiceTaxRate?.message} />
              <StyledTextField label="書類の交付を受ける事業者の氏名または名称" {...registerMui(register('brokerCompany.invoiceReceiverName'))} error={!!errors.brokerCompany?.invoiceReceiverName} helperText={errors.brokerCompany?.invoiceReceiverName?.message} />
            </div>
            <div css={rowStyle}>
              <StyledTextField label="備考" {...registerMui(register('brokerCompany.note'))} error={!!errors.brokerCompany?.note} helperText={errors.brokerCompany?.note?.message} />
            </div>
          </>
        )}

        <div css={modalTitleStyle}>備考</div>
        <div css={rowStyle}>
          <StyledTextField label="メモ1" {...registerMui(register('contractAttribution.memo1'))} error={!!errors.contractAttribution?.memo1} helperText={errors.contractAttribution?.memo1?.message} />
          <StyledTextField label="メモ2" {...registerMui(register('contractAttribution.memo2'))} error={!!errors.contractAttribution?.memo2} helperText={errors.contractAttribution?.memo2?.message} />
        </div>
        <div css={rowStyle}>
          <StyledTextField label="メモ3" {...registerMui(register('contractAttribution.memo3'))} error={!!errors.contractAttribution?.memo3} helperText={errors.contractAttribution?.memo3?.message} />
          <StyledTextField label="使用用途" {...registerMui(register('contractAttribution.usage'))} error={!!errors.contractAttribution?.usage} helperText={errors.contractAttribution?.usage?.message} />
        </div>

        <div css={modalTitleStyle}>契約条件</div>
        <div css={rowStyle}>
          <FormControl css={selectFormStyle}>
            <InputLabel id="taxCategory" variant="standard" shrink>税区分</InputLabel>
            <Select label="税区分" labelId="taxCategory" variant="standard"
              {...registerMui(register('contract.taxCategory'))} error={!!errors.contract?.taxCategory} defaultValue={defaultValues.contract.taxCategory ?? ''}>
              <MenuItem value=""></MenuItem>
              {taxCategory.map((value, index) => (<MenuItem key={index} value={index}>{value}</MenuItem>))}
            </Select>
          </FormControl>
          {isAdmin &&
            <StyledTextField type="number" label="仲介手数料(税込)" {...registerMui(register('contract.brokerageFeeWithTax'))} error={!!errors.contract?.brokerageFeeWithTax} helperText={errors.contract?.brokerageFeeWithTax?.message} />
          }
        </div>
        {isAdmin && (
          <>
            <div css={rowStyle}>
              <StyledTextField type="number" label="初回保証料(非課税)" {...registerMui(register('contract.initialGuaranteeFeeWithoutTax'))} error={!!errors.contract?.initialGuaranteeFeeWithoutTax} helperText={errors.contract?.initialGuaranteeFeeWithoutTax?.message} />
              <StyledTextField type="number" label="月額保証料(非課税)" {...registerMui(register('contract.monthlyGuaranteeFeeWithoutTax'))} error={!!errors.contract?.monthlyGuaranteeFeeWithoutTax} helperText={errors.contract?.monthlyGuaranteeFeeWithoutTax?.message} />
            </div>
            <div css={rowStyle}>
              <StyledTextField label="その他1" {...registerMui(register('contractAttribution.other1'))} error={!!errors.contractAttribution?.other1} helperText={errors.contractAttribution?.other1?.message} />
              <StyledTextField label="その他1_メモ" {...registerMui(register('contractAttribution.other1Memo'))} error={!!errors.contractAttribution?.other1Memo} helperText={errors.contractAttribution?.other1Memo?.message} />
            </div>
            <div css={rowStyle}>
              <StyledTextField label="その他2" {...registerMui(register('contractAttribution.other2'))} error={!!errors.contractAttribution?.other2} helperText={errors.contractAttribution?.other2?.message} />
              <StyledTextField label="その他2_メモ" {...registerMui(register('contractAttribution.other2Memo'))} error={!!errors.contractAttribution?.other2Memo} helperText={errors.contractAttribution?.other2Memo?.message} />
            </div>
            <div css={rowStyle}>
              <StyledTextField label="その他3" {...registerMui(register('contractAttribution.other3'))} error={!!errors.contractAttribution?.other3} helperText={errors.contractAttribution?.other3?.message} />
              <StyledTextField label="その他3_メモ" {...registerMui(register('contractAttribution.other3Memo'))} error={!!errors.contractAttribution?.other3Memo} helperText={errors.contractAttribution?.other3Memo?.message} />
            </div>
          </>
        )}

        {isAdmin && (
          <>
            <div css={modalTitleStyle}>お知らせ・注意喚起のメモ</div>
            <div css={rowStyle}>
              <StyledTextField label="メモ1" {...registerMui(register('contractAttribution.noticeMemo1'))} error={!!errors.contractAttribution?.noticeMemo1} helperText={errors.contractAttribution?.noticeMemo1?.message} />
              <StyledTextField label="メモ2" {...registerMui(register('contractAttribution.noticeMemo2'))} error={!!errors.contractAttribution?.noticeMemo2} helperText={errors.contractAttribution?.noticeMemo2?.message} />
            </div>
            <div css={rowStyle}>
              <StyledTextField label="メモ3" {...registerMui(register('contractAttribution.noticeMemo3'))} error={!!errors.contractAttribution?.noticeMemo3} helperText={errors.contractAttribution?.noticeMemo3?.message} />
            </div>
          </>
        )}

        {isAdmin && (
          <>
            <div css={modalTitleStyle}>その他費用</div>
            <div css={rowStyle}>
              <div></div>
              <FormControl css={selectFormStyle}>
              <InputLabel id="contract.seasonalAdjustment" variant="standard" shrink>季節調整</InputLabel>
              <Select label="季節調整" labelId="seasonalAdjustment" variant="standard" {...registerMui(register('contract.seasonalAdjustment'))} error={!!errors.contract?.seasonalAdjustment} defaultValue={defaultValues.contract.seasonalAdjustment ?? ''}>
                <MenuItem value=""></MenuItem>
                {seasonalAdjustment.map((value, index) => (<MenuItem key={index} value={index}>{value}</MenuItem>))}
              </Select>
              </FormControl>
            </div>
            <div css={rowStyle}>
              <FormControl css={selectFormStyle}>
              <InputLabel id="seasonalRentFrom" variant="standard" shrink>季節賃料FROM</InputLabel>
              <Select label="季節調整" labelId="seasonalRentFrom" variant="standard" {...registerMui(register('contract.seasonalRentFrom'))} error={!!errors.contract?.seasonalRentFrom} defaultValue={defaultValues.contract.seasonalRentFrom ?? 0}>
                <MenuItem value={0}></MenuItem>
                {jpMonthLabels.map((value, index) => <MenuItem key={index} value={1 + index}>{value}</MenuItem>)}
              </Select>
              </FormControl>
              <FormControl css={selectFormStyle}>
              <InputLabel id="seasonalRentTo" variant="standard" shrink>季節賃料TO</InputLabel>
              <Select label="季節調整" labelId="seasonalRentTo" variant="standard" {...registerMui(register('contract.seasonalRentTo'))} error={!!errors.contract?.seasonalRentTo} defaultValue={defaultValues.contract.seasonalRentTo ?? 0}>
                <MenuItem value={0}></MenuItem>
                {jpMonthLabels.map((value, index) => <MenuItem key={index} value={1 + index}>{value}</MenuItem>)}
              </Select>
              </FormControl>
            </div>
            <div css={rowStyle}>
              <StyledTextField type="number" label="季節費用(税込)" {...registerMui(register('contract.seasonalFeeWithTax'))} error={!!errors.contract?.seasonalFeeWithTax} helperText={errors.contract?.seasonalFeeWithTax?.message} />
              <StyledTextField type="number" label="日割賃料返金(税込)" {...registerMui(register('contract.proratedRentRefund'))} error={!!errors.contract?.proratedRentRefund} helperText={errors.contract?.proratedRentRefund?.message} />
            </div>
            <div css={rowStyle}>
              <StyledTextField type="number" label="敷金返金(非課税)" {...registerMui(register('contract.securityDepositRefund'))} error={!!errors.contract?.securityDepositRefund} helperText={errors.contract?.securityDepositRefund?.message} />
            </div>
          </>
        )}

        {isAdmin && (
          <>
            <div css={modalTitleStyle}>支払い方法</div>
            <div css={rowStyle}>
              <FormControl css={selectFormStyle}>
                <InputLabel id="paymentMethod" variant="standard">支払先</InputLabel>
                <Select label="支払先" labelId="paymentMethod" variant="standard" {...registerMui(register('contract.paymentMethod'))} error={!!errors.contract?.paymentMethod} defaultValue={defaultValues.contract.paymentMethod ?? ''}>
                  <MenuItem value=""></MenuItem>
                  {paymentMethod.map((value, index) => (<MenuItem key={index} value={index}>{value}</MenuItem>))}
                </Select>
              </FormControl>
            </div>
          </>
        )}

        <div css={footerButtonsStyle}>
          <LoadingButton variant="contained" onClick={handleSubmit(updateContract)} css={footerButtonStyle} loading={isUploading} disabled={!(isDirty && isValid) || isUploading}>保存</LoadingButton>
          <Button variant="text" onClick={() => {onClose(false); reset(defaultValues);}} css={footerButtonStyle} disabled={isUploading}>キャンセル</Button>
        </div>
      </div>
    </Modal>
  )
}

const LocalizedKeys: { [key: string]: string } = {
  // 基本情報
  initial_procedure_type: '初回契約手続き区分',
  // 利用者情報
  parking_user__employee_number: '利用者従業員番号',
  parking_user__affiliation_branch_name: '利用者所属拠点名',
  parking_user__affiliation_branch_number: '利用者所属拠点番号',
  parking_user__affiliation_department_name: '利用者所属組織名',
  parking_user__affiliation_department_number: '利用者所属組織番号',
  // 契約者情報
  parking_contractor__employee_number: '契約者従業員番号',
  parking_contractor__affiliation_branch_name: '契約者所属拠点名',
  parking_contractor__affiliation_branch_number: '契約者所属拠点番号',
  parking_contractor__affiliation_department_name: '契約者所属組織名',
  parking_contractor__affiliation_department_number: '契約者所属組織番号',
  // 管理会社情報
  client_company__client_company_postal_code: '管理会社郵便番号',
  client_company__guarantor_company_name: '保証会社名',
  client_company__guarantor_invoice_number: '保証会社インボイス登録番号',
  client_company__invoice_tax_rate: '（管理会社）適用税率',
  client_company__invoice_receiver_name: '（管理会社）書類の交付を受ける事業者の氏名または名称',
  // 貸主情報
  lessor__lessor_type: '貸主区分',
  lessor__postal_code: '貸主郵便番号',
  lessor__address: '貸主住所',
  lessor__phone_number: '貸主電話番号',
  lessor__fax_number: '貸主FAX番号',
  lessor__email: '貸主メールアドレス',
  lessor__invoice_tax_rate: '（貸主）適用税率',
  lessor__invoice_receiver_name: '（貸主）書類の交付を受ける事業者の氏名または名称',
  lessor__note: '備考',
  // 仲介会社情報
  broker_company__broker_company_name: '仲介会社名',
  broker_company__postal_code: '仲介会社郵便番号',
  broker_company__address: '仲介会社住所',
  broker_company__phone_number: '仲介会社電話番号',
  broker_company__fax_number: '仲介会社FAX番号',
  broker_company__email: '仲介会社メールアドレス',
  broker_company__invoice_name_or_number: '（仲介会社）インボイス発行事業者の氏名または名称および登録番号',
  broker_company__invoice_tax_rate: '（仲介会社）適用税率',
  broker_company__invoice_receiver_name: '（仲介会社）書類の交付を受ける事業者の氏名または名称',
  broker_company__note: '備考',
  // 備考　※管理者のみ編集可能
  contract_attribution__notice_memo1: 'メモ1',
  contract_attribution__notice_memo2: 'メモ2',
  contract_attribution__notice_memo3: 'メモ3',
  // 契約条件
  contract_condition__tax_category: '税区分', 
  contract_condition__brokerage_fee_with_tax: '仲介手数料(税込)',
  contract_condition__initial_guarantee_fee_without_tax: '初回保証料(非課税)',
  contract_condition__monthly_guarantee_fee_without_tax: '月額保証料(非課税)',
  contract_attribution__other1: 'その他1',
  contract_attribution__other1_memo: 'その他1_メモ',
  contract_attribution__other2: 'その他2',
  contract_attribution__other2_memo: 'その他2_メモ',
  contract_attribution__other3: 'その他3',
  contract_attribution__other3_memo: 'その他3_メモ',
  // お知らせ・注意喚起のメモ (管理者のみ編集可能)
  contract_attribution__memo1: 'メモ1',
  contract_attribution__memo2: 'メモ2',
  contract_attribution__memo3: 'メモ3',
  // その他費用
  contract_condition__seasonal_adjustment: '季節調整',
  contract_condition__seasonal_rent_from: '季節賃料FROM',
  contract_condition__seasonal_rent_to: '季節賃料TO',
  contract_condition__seasonal_fee_with_tax: '季節費用(税込)',
  contract_condition__prorated_rent_refund: '日割賃料返金(税込)',
  contract_condition__security_deposit_refund: '敷金返金(非課税)',
  // 支払い方法
  contract_condition__payment_method: '支払い方法',
} as const;

const showError = (response: any) => {
  alert(convert(response));
};

const convert = (data: any, section?: string): string => {
  let messages = '';

  if (typeof data === 'object') {
    const keys = Object.keys(data);
    keys.forEach(key => {
      if (Array.isArray(data[key])) {
        const localizedKey = LocalizedKeys[(section ? section + '__' : '') + key] ?? key;
        messages += `${localizedKey}\n` + data[key].map((message: string) => `  ・${message}\n`) + "\n";
      } else {
        messages += convert(data[key], key);
      }
    });
  }

  return messages;
}

const jpMonthLabels =  Array.from({ length: 12 }, (_, i) => `${i + 1}月`);

// Styles
const modalStyle = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const modalContentStyle = css({
  backgroundColor: 'white',
  borderRadius: '10px',
  width: '80vw',
  borderWidth: 0,
  padding: '20px',
  overflow: 'scroll',
  maxHeight: '90vh',
});

const modalTitleStyle = css({
  color: 'black',
  backgroundColor: '#F6F6F6',
  padding: '10px',
  marginBottom: '20px',
  fontSize: '14px',
  fontWeight: 'bold',
});

const rowStyle = css({
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: '20px',
  gap: '0 2%',
});

const footerButtonsStyle = css({
  textAlign: 'center',
});

const footerButtonStyle = css({
  width: '120px',
  marginLeft: '20px',
  marginRight: '20px',
});

const selectFormStyle = css({
  width: '49%',
});

const StyledTextField = styled(TextField) <{ link?: boolean }>`
  width: 49%;
  ${({ link }) => link ? '.MuiInput-input.Mui-disabled { cursor: pointer; -webkit-text-fill-color: #0056B0; }' : ''};
`;
StyledTextField.defaultProps = {
  variant: 'standard',
  InputLabelProps: {
    shrink: true,
  },
};

const StyledFileField = styled(TextField) <{ link?: boolean }>`
  width: 100%;
  ${({ link }) => link ? '.MuiInput-input.Mui-disabled { cursor: pointer; -webkit-text-fill-color: #0056B0; }' : ''};
`;
StyledFileField.defaultProps = {
  variant: 'standard',
  InputLabelProps: {
    shrink: true,
  },
};
