import React, { useEffect, useState } from "react";
import "./App.css";
import { BrowserRouter } from "react-router-dom";
import { Route, Routes } from "react-router";
import { NewContract } from "./components/usecase/NewContract";
import { HomeComp } from "./components/usecase/HomeComp";
import { Login } from "./components/usecase/login/Login";
import { Sidebar } from "./components/usecase/Sidebar";
// TODO: DEBT M1 2025/02/13 https://nealle.atlassian.net/browse/PDBZD-63
// import { NewContractCreateComp } from "./components/usecase/NewContractCreateComp";
import { ContractListComp } from "./components/usecase/ContractListComp";
import { ContractDetailComp } from "./components/usecase/ContractDetailComp";
import { PaymentListComp } from "./components/usecase/PaymentListComp";
import { RenewContractListComp } from "./components/usecase/RenewContractListComp";
import { NullPaymentListComp } from "./components/usecase/NullPaymentListComp";
import { ImportContractComp } from "./components/usecase/ImportContractComp";
import { UnsetOrganizationContractListComp } from "./components/usecase/UnsetOrganizationContractListComp";
import { AccountComp } from "./components/usecase/AccountComp";
import { UserListComp } from "./components/usecase/UserListComp";
import { StatementComp } from "./components/usecase/StatementComp";
import { ThemeProvider } from "@mui/material";
import { theme } from "./muiTheme";
import 'react-image-lightbox/style.css';
import { eventManager } from "./util/eventManager";
import { ErrorDialog } from "./components/parts/ErrorDialog";
import { StatementDownloadComp } from "./components/usecase/StatementDownloadComp";

const App = () => {
  const [errorEvent, setErrorEvent] = useState<Event>();

  useEffect(() => {
    eventManager.on("APIError", (event: Event) => setErrorEvent(event));
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/:organization_id/login" element={<Login />} />
            <Route path="/" element={<Sidebar />}>
              <Route path="/" element={<HomeComp />} />
              {/* TODO: DEBT M1 2025/02/13 https://nealle.atlassian.net/browse/PDBZD-63 */}
              {/* <Route path="/new-contract-create/" element={<NewContractCreateComp /> }  /> */}
              <Route path="/contract-list/:page" element={<ContractListComp />} />
              <Route path="/contract-list/" element={<ContractListComp />} />
              <Route path="/contract-detail/:id" element={<ContractDetailComp />} />
              <Route path="/payment-list/:page" element={<PaymentListComp />} />
              <Route path="/null-payment-list/:page" element={<NullPaymentListComp />} />
              <Route path="new_contract" element={<NewContract />}></Route>
              <Route path="/renew-contract-list/:page" element={<RenewContractListComp />} />
              <Route path="/import-direct/" element={<ImportContractComp />} />
              <Route path="/unset-organization-contract-list/:page" element={<UnsetOrganizationContractListComp />} />
              <Route path="/unset-organization-contract-list/" element={<UnsetOrganizationContractListComp />} />
              <Route path="/user-list/:page" element={<UserListComp />} />
              <Route path="/account/" element={<AccountComp />} />
              <Route path="/account/:id" element={<AccountComp />} />
              <Route path="/statement/" element={<StatementComp />} />
              <Route path="/statement/download" element={<StatementDownloadComp />} />
            </Route>
          </Routes>
      </BrowserRouter>
      <ErrorDialog errorEvent={errorEvent} dismiss={() => setErrorEvent(undefined)} />
    </ThemeProvider>
  );
};

export default App;
