import { isNotNullOrUndefined } from "../../util/falsyCheck";
import { isEmpty } from "../../util/objectUtil";
import { toSnakeCase } from "../../util/toScakeCase";

interface ParkingUser {
  name?: string;
  name_kana?: string;
  manager?: string;
  email?: string;
  mobile_phone_number?: string;
  phone_number?: string;
  employee_number?: string;
  affiliation_branch_name?: string;
  affiliation_branch_number?: string;
  affiliation_department_name?: string;
  affiliation_department_number?: string;
}

interface ParkingContractor {
  name?: string;
  name_kana?: string;
  manager?: string;
  email?: string;
  phone_number?: string;
  company_phone_number?: string;
  employee_number?: string;
  affiliation_branch_name?: string;
  affiliation_branch_number?: string;
  affiliation_department_name?: string;
  affiliation_department_number?: string;
}

interface ClientCompany {
  client_company_name?: string;
  client_store_name?: string;
  parking_name?: string;
  partition?: string | null;
  partition_type?: number | null;
  address?: string | null;
  latitude?: number | null;
  longitude?: number | null;
  partition_image1?: string | null;
  partition_image2?: string | null;
  partition_image3?: string | null;
  client_company_postal_code?: string | null;
  client_company_address?: string;
  phone_number?: string;
  fax_number?: string;
  staff_name?: string;
  staff_email?: string;
  invoice_name_or_number?: string | null;
  invoice_tax_rate?: number | null;
  invoice_receiver_name?: string | null;
  guarantor_company_name?: string | null;
  guarantor_invoice_number?: string | null;
  note?: string;
}

interface Lessor {
  lessor_type?: number | null,
  lessor_name?: string,
  postal_code?: string | null,
  address?: string,
  phone_number?: string,
  fax_number?: string,
  email?: string,
  invoice_name_or_number?: string | null,
  invoice_tax_rate?: number | null,
  invoice_receiver_name?: string | null,
  note?: string,
}

interface BrokerCompany {
  broker_company_name?: string;
  postal_code?: string | null;
  address?: string;
  phone_number?: string;
  fax_number?: string;
  email?: string;
  invoice_name_or_number?: string | null;
  invoice_tax_rate?: number | null;
  invoice_receiver_name?: string | null;
  note?: string;
}

interface ContractVehicle {
  car_model?: string;
  regno_region_name?: string;
  regno_class_digit?: string;
  regno_hiragana?: string;
  regno_seq_digit?: string;
  certificate?: string[];
}

interface ContractCondition {
  tax_category?: number | null;
  rent_with_tax?: string;
  rent_without_tax?: string;
  first_month_rent_with_tax?: string;
  final_month_rent_with_tax?: string;
  first_month_rent_without_tax?: string;
  final_month_rent_without_tax?: string;
  monthly_guarantee_and_management_fee_with_tax?: string;
  initial_guarantee_and_management_fee_with_tax?: string;
  first_month_guarantee_and_management_fee_with_tax?: string;
  final_month_guarantee_and_management_fee_with_tax?: string;
  update_base_fee_with_tax?: string;
  update_management_fee_with_tax?: string;
  renewal_management_fee_with_tax?: string;
  deposit_with_tax?: string;
  key_money_with_tax?: string;
  administrative_fee_with_tax?: string;
  brokerage_fee_with_tax?: number | null;
  issue_paper_fee_with_tax?: string;
  contracted_date?: Date;
  first_start_date?: Date;
  start_date?: Date;
  end_date?: Date;
  contract_period_month?: number
  cancel_date?: Date;
  need_issue_paper?: boolean;
  issue_date?: Date;
  payment_method?: number;
  payment_date?: string;
  initial_payment_date?: Date;
  seasonal_adjustment?: number | null;
  seasonal_rent_from?: number | null;
  seasonal_rent_to?: number | null;
  seasonal_fee_with_tax?: number | null;
  prorated_rent_refund?: number | null;
  security_deposit_refund?: number | null;
  initial_guarantee_fee_without_tax?: number | null;
  monthly_guarantee_fee_without_tax?: number | null;
}

interface ContractAttribution {
  other1?: string;
  other1_memo?: string;
  other2?: string;
  other2_memo?: string;
  other3?: string;
  other3_memo?: string;
  notice_memo1?: string;
  notice_memo2?: string;
  notice_memo3?: string;
  memo1?: string;
  memo2?: string;
  memo3?: string;
  usage?: string;
  init_payment_company_name?: string;
  init_payment_send_money_date?: Date;
  init_payment_bank_number?: string;
  init_payment_bank_name?: string;
  init_payment_branch_store_number?: string;
  init_payment_branch_name?: string;
  init_payment_deposit_kind_type?: number;
  init_payment_bank_account_number?: string;
  init_payment_bank_account_name?: string;
  init_payment_send_money_amount?: number;
  monthly_payment_company_name?: string;
  monthly_payment_send_money_date?: Date;
  monthly_payment_bank_number?: string;
  monthly_payment_bank_name?: string;
  monthly_payment_branch_store_number?: string;
  monthly_payment_branch_name?: string;
  monthly_payment_deposit_kind_type?: number;
  monthly_payment_bank_account_number?: string;
  monthly_payment_bank_account_name?: string;
  monthly_payment_send_money_amount?: number;
}

interface Contract {
  contract_kind?: number;
  payment_bank?: string | null;
  management_state?: number;
  normal_contract?: boolean;
  auto_update?: boolean;
  rental_item?: boolean;
  rental_item_contents?: string | null;
  initial_procedure_type?: number | null;
  parking_user?: ParkingUser;
  parking_contractor?: ParkingContractor;
  client_company?: ClientCompany;
  lessor?: Lessor;
  broker_company?: BrokerCompany;
  contract_vehicle?: ContractVehicle;
  contract_condition?: ContractCondition;
  contract_attribution?: ContractAttribution;
}

export const convertFromContractDetail = (updateContract: any) => {
  const parkingUser: ParkingUser = {
    ...(isNotNullOrUndefined(updateContract?.user?.name) && { name: updateContract.user.name }),
    ...(isNotNullOrUndefined(updateContract?.user?.nameKana) && { name_kana: updateContract.user.nameKana }),
    ...(isNotNullOrUndefined(updateContract?.user?.manager) && { manager: updateContract.user.manager }),
    ...(isNotNullOrUndefined(updateContract?.user?.email) && { email: updateContract.user.email }),
    ...(isNotNullOrUndefined(updateContract?.user?.employeeNumber) && { employee_number: updateContract.user.employeeNumber }),
    ...(isNotNullOrUndefined(updateContract?.user?.affiliationBranchName) && { affiliation_branch_name: updateContract.user.affiliationBranchName }),
    ...(isNotNullOrUndefined(updateContract?.user?.affiliationBranchNumber) && { affiliation_branch_number: updateContract.user.affiliationBranchNumber }),
    ...(isNotNullOrUndefined(updateContract?.user?.affiliationDepartmentName) && { affiliation_department_name: updateContract.user.affiliationDepartmentName }),
    ...(isNotNullOrUndefined(updateContract?.user?.affiliationDepartmentNumber) && { affiliation_department_number: updateContract.user.affiliationDepartmentNumber }),
    ...(isNotNullOrUndefined(updateContract?.user?.mobilePhoneNumber) && { mobile_phone_number: updateContract.user.mobilePhoneNumber }),
    ...(isNotNullOrUndefined(updateContract?.user?.phoneNumber) && { phone_number: updateContract.user.phoneNumber }),
  };

  const parkingContractor: ParkingContractor = {
    ...(isNotNullOrUndefined(updateContract?.contractor?.name) && { name: updateContract.contractor.name }),
    ...(isNotNullOrUndefined(updateContract?.contractor?.nameKana) && { name_kana: updateContract.contractor.nameKana }),
    ...(isNotNullOrUndefined(updateContract?.contractor?.manager) && { manager: updateContract.contractor.manager }),
    ...(isNotNullOrUndefined(updateContract?.contractor?.email) && { email: updateContract.contractor.email }),
    ...(isNotNullOrUndefined(updateContract?.contractor?.affiliationBranchName) && { affiliation_branch_name: updateContract.contractor.affiliationBranchName }),
    ...(isNotNullOrUndefined(updateContract?.contractor?.affiliationBranchNumber) && { affiliation_branch_number: updateContract.contractor.affiliationBranchNumber }),
    ...(isNotNullOrUndefined(updateContract?.contractor?.affiliationDepartmentName) && { affiliation_department_name: updateContract.contractor.affiliationDepartmentName }),
    ...(isNotNullOrUndefined(updateContract?.contractor?.affiliationDepartmentNumber) && { affiliation_department_number: updateContract.contractor.affiliationDepartmentNumber }),
    ...(isNotNullOrUndefined(updateContract?.contractor?.employeeNumber) && { employee_number: updateContract.contractor.employeeNumber }),
    ...(isNotNullOrUndefined(updateContract?.contractor?.phoneNumber) && { phone_number: updateContract.contractor.phoneNumber }),
    ...(isNotNullOrUndefined(updateContract?.contractor?.companyPhoneNumber) && { company_phone_number: updateContract.contractor.companyPhoneNumber }),
  };
  
  const clientCompany: ClientCompany = {
    ...(isNotNullOrUndefined(updateContract?.clientCompany?.clientCompanyName) && { client_company_name: updateContract.clientCompany.clientCompanyName }),
    ...(isNotNullOrUndefined(updateContract?.clientCompany?.clientStoreName) && { client_store_name: updateContract.clientCompany.clientStoreName }),
    ...(isNotNullOrUndefined(updateContract?.clientCompany?.parkingName) && { parking_name: updateContract.clientCompany.parkingName }),
    ...(isNotNullOrUndefined(updateContract?.clientCompany?.partition) && { partition: updateContract.clientCompany.partition }),
    ...(isNotNullOrUndefined(updateContract?.clientCompany?.partitionType) && { partition_type: updateContract.clientCompany.partitionType }),
    ...(isNotNullOrUndefined(updateContract?.clientCompany?.address) && { address: updateContract.clientCompany.address }),
    ...(isNotNullOrUndefined(updateContract?.clientCompany?.latitude) && { latitude: updateContract.clientCompany.latitude }),
    ...(isNotNullOrUndefined(updateContract?.clientCompany?.longitude) && { longitude: updateContract.clientCompany.longitude }),
    ...(isNotNullOrUndefined(updateContract?.clientCompany?.partitionImage1) && { partition_image1: updateContract.clientCompany.partitionImage1 }),
    ...(isNotNullOrUndefined(updateContract?.clientCompany?.partitionImage2) && { partition_image2: updateContract.clientCompany.partitionImage2 }),
    ...(isNotNullOrUndefined(updateContract?.clientCompany?.partitionImage3) && { partition_image3: updateContract.clientCompany.partitionImage3 }),
    ...(isNotNullOrUndefined(updateContract?.clientCompany?.clientCompanyPostalCode) && { client_company_postal_code: updateContract.clientCompany.clientCompanyPostalCode }),
    ...(isNotNullOrUndefined(updateContract?.clientCompany?.clientCompanyAddress) && { client_company_address: updateContract.clientCompany.clientCompanyAddress }),
    ...(isNotNullOrUndefined(updateContract?.clientCompany?.phoneNumber) && { phone_number: updateContract.clientCompany.phoneNumber }),
    ...(isNotNullOrUndefined(updateContract?.clientCompany?.faxNumber) && { fax_number: updateContract.clientCompany.faxNumber }),
    ...(isNotNullOrUndefined(updateContract?.clientCompany?.staffName) && { staff_name: updateContract.clientCompany.staffName }),
    ...(isNotNullOrUndefined(updateContract?.clientCompany?.staffEmail) && { staff_email: updateContract.clientCompany.staffEmail }),
    ...(isNotNullOrUndefined(updateContract?.clientCompany?.invoiceNameOrNumber) && { invoice_name_or_number: updateContract.clientCompany.invoiceNameOrNumber }),
    ...(isNotNullOrUndefined(updateContract?.clientCompany?.invoiceTaxRate) && { invoice_tax_rate: updateContract.clientCompany.invoiceTaxRate }),
    ...(isNotNullOrUndefined(updateContract?.clientCompany?.invoiceReceiverName) && { invoice_receiver_name: updateContract.clientCompany.invoiceReceiverName }),
    ...(isNotNullOrUndefined(updateContract?.clientCompany?.guarantorCompanyName) && { guarantor_company_name: updateContract.clientCompany.guarantorCompanyName }),
    ...(isNotNullOrUndefined(updateContract?.clientCompany?.guarantorInvoiceNumber) && { guarantor_invoice_number: updateContract.clientCompany.guarantorInvoiceNumber }),
    ...(isNotNullOrUndefined(updateContract?.clientCompany?.note) && { note: updateContract.clientCompany.note }),
  };

  const lessor: Lessor = {
    ...(isNotNullOrUndefined(updateContract?.lessor?.lessorType) && { lessor_type: updateContract.lessor.lessorType }),
    ...(isNotNullOrUndefined(updateContract?.lessor?.lessorName) && { lessor_name: updateContract.lessor.lessorName }),
    ...(isNotNullOrUndefined(updateContract?.lessor?.postalCode) && { postal_code: updateContract.lessor.postalCode }),
    ...(isNotNullOrUndefined(updateContract?.lessor?.address) && { address: updateContract.lessor.address }),
    ...(isNotNullOrUndefined(updateContract?.lessor?.phoneNumber) && { phone_number: updateContract.lessor.phoneNumber }),
    ...(isNotNullOrUndefined(updateContract?.lessor?.faxNumber) && { fax_number: updateContract.lessor.faxNumber }),
    ...(isNotNullOrUndefined(updateContract?.lessor?.email) && { email: updateContract.lessor.email }),
    ...(isNotNullOrUndefined(updateContract?.lessor?.invoiceNameOrNumber) && { invoice_name_or_number: updateContract.lessor.invoiceNameOrNumber }),
    ...(isNotNullOrUndefined(updateContract?.lessor?.invoiceTaxRate) && { invoice_tax_rate: updateContract.lessor.invoiceTaxRate }),
    ...(isNotNullOrUndefined(updateContract?.lessor?.invoiceReceiverName) && { invoice_receiver_name: updateContract.lessor.invoiceReceiverName }),
    ...(isNotNullOrUndefined(updateContract?.lessor?.note) && { note: updateContract.lessor.note }),
  };

  const brokerCompany: BrokerCompany = {
    ...(isNotNullOrUndefined(updateContract?.brokerCompany?.brokerCompanyName) && { broker_company_name: updateContract.brokerCompany.brokerCompanyName }),
    ...(isNotNullOrUndefined(updateContract?.brokerCompany?.postalCode) && { postal_code: updateContract.brokerCompany.postalCode }),
    ...(isNotNullOrUndefined(updateContract?.brokerCompany?.address) && { address: updateContract.brokerCompany.address }),
    ...(isNotNullOrUndefined(updateContract?.brokerCompany?.phoneNumber) && { phone_number: updateContract.brokerCompany.phoneNumber }),
    ...(isNotNullOrUndefined(updateContract?.brokerCompany?.faxNumber) && { fax_number: updateContract.brokerCompany.faxNumber }),
    ...(isNotNullOrUndefined(updateContract?.brokerCompany?.email) && { email: updateContract.brokerCompany.email }),
    ...(isNotNullOrUndefined(updateContract?.brokerCompany?.invoiceNameOrNumber) && { invoice_name_or_number: updateContract.brokerCompany.invoiceNameOrNumber }),
    ...(isNotNullOrUndefined(updateContract?.brokerCompany?.invoiceTaxRate) && { invoice_tax_rate: updateContract.brokerCompany.invoiceTaxRate }),
    ...(isNotNullOrUndefined(updateContract?.brokerCompany?.invoiceReceiverName) && { invoice_receiver_name: updateContract.brokerCompany.invoiceReceiverName }),
    ...(isNotNullOrUndefined(updateContract?.brokerCompany?.note) && { note: updateContract.brokerCompany.note }),
  };

  const contractVehicle: ContractVehicle = {
    ...(isNotNullOrUndefined(updateContract?.carInfo?.carModel) && { car_model: updateContract.carInfo.carModel }),
    ...(isNotNullOrUndefined(updateContract?.carInfo?.regnoRegionName) && { regno_region_name: updateContract.carInfo.regnoRegionName }),
    ...(isNotNullOrUndefined(updateContract?.carInfo?.regnoClassDigit) && { regno_class_digit: updateContract.carInfo.regnoClassDigit }),
    ...(isNotNullOrUndefined(updateContract?.carInfo?.regnoHiragana) && { regno_hiragana: updateContract.carInfo.regnoHiragana }),
    ...(isNotNullOrUndefined(updateContract?.carInfo?.regnoSeqDigit) && { regno_seq_digit: updateContract.carInfo.regnoSeqDigit }),
    ...(isNotNullOrUndefined(updateContract?.carInfo?.certificate) && { certificate: updateContract.carInfo.certificate }),
  };

  const contractCondition: ContractCondition = {
    ...(isNotNullOrUndefined(updateContract?.contract?.taxCategory) && { tax_category: updateContract.contract.taxCategory }),
    ...(isNotNullOrUndefined(updateContract?.contract?.rentWithTax) && { rent_with_tax: updateContract.contract.rentWithTax }),
    ...(isNotNullOrUndefined(updateContract?.contract?.rentWithoutTax) && { rent_without_tax: updateContract.contract.rentWithoutTax }),
    ...(isNotNullOrUndefined(updateContract?.contract?.firstMonthRentWithTax) && { first_month_rent_with_tax: updateContract.contract.firstMonthRentWithTax }),
    ...(isNotNullOrUndefined(updateContract?.contract?.finalMonthRentWithTax) && { final_month_rent_with_tax: updateContract.contract.finalMonthRentWithTax }),
    ...(isNotNullOrUndefined(updateContract?.contract?.firstMonthRentWithoutTax) && { first_month_rent_without_tax: updateContract.contract.firstMonthRentWithoutTax }),
    ...(isNotNullOrUndefined(updateContract?.contract?.finalMonthRentWithoutTax) && { final_month_rent_without_tax: updateContract.contract.finalMonthRentWithoutTax }),
    ...(isNotNullOrUndefined(updateContract?.contract?.monthlyGuaranteeAndManagementFeeWithTax) && { monthly_guarantee_and_management_fee_with_tax: updateContract.contract.monthlyGuaranteeAndManagementFeeWithTax }),
    ...(isNotNullOrUndefined(updateContract?.contract?.initialGuaranteeAndManagementFeeWithTax) && { initial_guarantee_and_management_fee_with_tax: updateContract.contract.initialGuaranteeAndManagementFeeWithTax }),
    ...(isNotNullOrUndefined(updateContract?.contract?.firstMonthGuaranteeAndManagementFeeWithTax) && { first_month_guarantee_and_management_fee_with_tax: updateContract.contract.firstMonthGuaranteeAndManagementFeeWithTax }),
    ...(isNotNullOrUndefined(updateContract?.contract?.finalMonthGuaranteeAndManagementFeeWithTax) && { final_month_guarantee_and_management_fee_with_tax: updateContract.contract.finalMonthGuaranteeAndManagementFeeWithTax }),
    ...(isNotNullOrUndefined(updateContract?.contract?.updateBaseFeeWithTax) && { update_base_fee_with_tax: updateContract.contract.updateBaseFeeWithTax }),
    ...(isNotNullOrUndefined(updateContract?.contract?.updateManagementFeeWithTax) && { update_management_fee_with_tax: updateContract.contract.updateManagementFeeWithTax }),
    ...(isNotNullOrUndefined(updateContract?.contract?.renewalManagementFeeWithTax) && { renewal_management_fee_with_tax: updateContract.contract.renewalManagementFeeWithTax }),
    ...(isNotNullOrUndefined(updateContract?.contract?.depositWithTax) && { deposit_with_tax: updateContract.contract.depositWithTax }),
    ...(isNotNullOrUndefined(updateContract?.contract?.keyMoneyWithTax) && { key_money_with_tax: updateContract.contract.keyMoneyWithTax }),
    ...(isNotNullOrUndefined(updateContract?.contract?.administrativeFeeWithTax) && { administrative_fee_with_tax: updateContract.contract.administrativeFeeWithTax }),
    ...(isNotNullOrUndefined(updateContract?.contract?.brokerageFeeWithTax) && { brokerage_fee_with_tax: updateContract.contract.brokerageFeeWithTax }),
    ...(isNotNullOrUndefined(updateContract?.contract?.issuePaperFeeWithTax) && { issue_paper_fee_with_tax: updateContract.contract.issuePaperFeeWithTax }),
    ...(isNotNullOrUndefined(updateContract?.contract?.contractedDate) && { contracted_date: updateContract.contract.contractedDate }),
    ...(isNotNullOrUndefined(updateContract?.contract?.firstStartDate) && { first_start_date: updateContract.contract.firstStartDate }),
    ...(isNotNullOrUndefined(updateContract?.contract?.startDate) && { start_date: updateContract.contract.startDate }),
    ...(isNotNullOrUndefined(updateContract?.contract?.endDate) && { end_date: updateContract.contract.endDate }),
    ...(isNotNullOrUndefined(updateContract?.contract?.contractPeriodMonth) && { contract_period_month: updateContract.contract.contractPeriodMonth }),
    ...(isNotNullOrUndefined(updateContract?.contract?.cancelDate) && { cancel_date: updateContract.contract.cancelDate }),
    ...(isNotNullOrUndefined(updateContract?.contract?.needIssuePaper) && { need_issue_paper: updateContract.contract.needIssuePaper }),
    ...(isNotNullOrUndefined(updateContract?.contract?.issueDate) && { issue_date: updateContract.contract.issueDate }),
    ...(isNotNullOrUndefined(updateContract?.contract?.paymentMethod) && { payment_method: updateContract.contract.paymentMethod }),
    ...(isNotNullOrUndefined(updateContract?.contract?.paymentDate) && { payment_date: updateContract.contract.paymentDate }),
    ...(isNotNullOrUndefined(updateContract?.contract?.initialPaymentDate) && { initial_payment_date: updateContract.contract.initialPaymentDate }),
    ...(isNotNullOrUndefined(updateContract?.contract?.seasonalAdjustment) && { seasonal_adjustment: updateContract.contract.seasonalAdjustment }),
    ...(isNotNullOrUndefined(updateContract?.contract?.seasonalRentFrom) && { seasonal_rent_from: updateContract.contract.seasonalRentFrom }),
    ...(isNotNullOrUndefined(updateContract?.contract?.seasonalRentTo) && { seasonal_rent_to: updateContract.contract.seasonalRentTo }),
    ...(isNotNullOrUndefined(updateContract?.contract?.seasonalFeeWithTax) && { seasonal_fee_with_tax: updateContract.contract.seasonalFeeWithTax }),
    ...(isNotNullOrUndefined(updateContract?.contract?.proratedRentRefund) && { prorated_rent_refund: updateContract.contract.proratedRentRefund }),
    ...(isNotNullOrUndefined(updateContract?.contract?.securityDepositRefund) && { security_deposit_refund: updateContract.contract.securityDepositRefund }),
    ...(isNotNullOrUndefined(updateContract?.contract?.initialGuaranteeFeeWithoutTax) && { initial_guarantee_fee_without_tax: updateContract.contract.initialGuaranteeFeeWithoutTax }),
    ...(isNotNullOrUndefined(updateContract?.contract?.monthlyGuaranteeFeeWithoutTax) && { monthly_guarantee_fee_without_tax: updateContract.contract.monthlyGuaranteeFeeWithoutTax }),
  };

  const contractAttribution: ContractAttribution = {
    ...(isNotNullOrUndefined(updateContract?.contractAttribution?.other1) && { other1: updateContract.contractAttribution.other1 }),
    ...(isNotNullOrUndefined(updateContract?.contractAttribution?.other1Memo) && { other1_memo: updateContract.contractAttribution.other1Memo }),
    ...(isNotNullOrUndefined(updateContract?.contractAttribution?.other2) && { other2: updateContract.contractAttribution.other2 }),
    ...(isNotNullOrUndefined(updateContract?.contractAttribution?.other2Memo) && { other2_memo: updateContract.contractAttribution.other2Memo }),
    ...(isNotNullOrUndefined(updateContract?.contractAttribution?.other3) && { other3: updateContract.contractAttribution.other3 }),
    ...(isNotNullOrUndefined(updateContract?.contractAttribution?.other3Memo) && { other3_memo: updateContract.contractAttribution.other3Memo }),
    ...(isNotNullOrUndefined(updateContract?.contractAttribution?.noticeMemo1) && { notice_memo1: updateContract.contractAttribution.noticeMemo1 }),
    ...(isNotNullOrUndefined(updateContract?.contractAttribution?.noticeMemo2) && { notice_memo2: updateContract.contractAttribution.noticeMemo2 }),
    ...(isNotNullOrUndefined(updateContract?.contractAttribution?.noticeMemo3) && { notice_memo3: updateContract.contractAttribution.noticeMemo3 }),
    ...(isNotNullOrUndefined(updateContract?.contractAttribution?.memo1) && { memo1: updateContract.contractAttribution.memo1 }),
    ...(isNotNullOrUndefined(updateContract?.contractAttribution?.memo2) && { memo2: updateContract.contractAttribution.memo2 }),
    ...(isNotNullOrUndefined(updateContract?.contractAttribution?.memo3) && { memo3: updateContract.contractAttribution.memo3 }),
    ...(isNotNullOrUndefined(updateContract?.contractAttribution?.usage) && { usage: updateContract.contractAttribution.usage }),
    ...(isNotNullOrUndefined(updateContract?.contractAttribution?.initPaymentCompanyName) && { init_payment_company_name: updateContract.contractAttribution.initPaymentCompanyName }),
    ...(isNotNullOrUndefined(updateContract?.contractAttribution?.initPaymentSendMoneyDate) && { init_payment_send_money_date: updateContract.contractAttribution.initPaymentSendMoneyDate }),
    ...(isNotNullOrUndefined(updateContract?.contractAttribution?.initPaymentBankNumber) && { init_payment_bank_number: updateContract.contractAttribution.initPaymentBankNumber }),
    ...(isNotNullOrUndefined(updateContract?.contractAttribution?.initPaymentBankName) && { init_payment_bank_name: updateContract.contractAttribution.initPaymentBankName }),
    ...(isNotNullOrUndefined(updateContract?.contractAttribution?.initPaymentBranchStoreNumber) && { init_payment_branch_store_number: updateContract.contractAttribution.initPaymentBranchStoreNumber }),
    ...(isNotNullOrUndefined(updateContract?.contractAttribution?.initPaymentBranchName) && { init_payment_branch_name: updateContract.contractAttribution.initPaymentBranchName }),
    ...(isNotNullOrUndefined(updateContract?.contractAttribution?.initPaymentDepositKindType) && { init_payment_deposit_kind_type: updateContract.contractAttribution.initPaymentDepositKindType }),
    ...(isNotNullOrUndefined(updateContract?.contractAttribution?.initPaymentBankAccountNumber) && { init_payment_bank_account_number: updateContract.contractAttribution.initPaymentBankAccountNumber }),
    ...(isNotNullOrUndefined(updateContract?.contractAttribution?.initPaymentBankAccountName) && { init_payment_bank_account_name: updateContract.contractAttribution.initPaymentBankAccountName }),
    ...(isNotNullOrUndefined(updateContract?.contractAttribution?.initPaymentSendMoneyAmount) && { init_payment_send_money_amount: updateContract.contractAttribution.initPaymentSendMoneyAmount }),
    ...(isNotNullOrUndefined(updateContract?.contractAttribution?.monthlyPaymentCompanyName) && { monthly_payment_company_name: updateContract.contractAttribution.monthlyPaymentCompanyName }),
    ...(isNotNullOrUndefined(updateContract?.contractAttribution?.monthlyPaymentSendMoneyDate) && { monthly_payment_send_money_date: updateContract.contractAttribution.monthlyPaymentSendMoneyDate }),
    ...(isNotNullOrUndefined(updateContract?.contractAttribution?.monthlyPaymentBankNumber) && { monthly_payment_bank_number: updateContract.contractAttribution.monthlyPaymentBankNumber }),
    ...(isNotNullOrUndefined(updateContract?.contractAttribution?.monthlyPaymentBankName) && { monthly_payment_bank_name: updateContract.contractAttribution.monthlyPaymentBankName }),
    ...(isNotNullOrUndefined(updateContract?.contractAttribution?.monthlyPaymentBranchStoreNumber) && { monthly_payment_branch_store_number: updateContract.contractAttribution.monthlyPaymentBranchStoreNumber }),
    ...(isNotNullOrUndefined(updateContract?.contractAttribution?.monthlyPaymentBranchName) && { monthly_payment_branch_name: updateContract.contractAttribution.monthlyPaymentBranchName }),
    ...(isNotNullOrUndefined(updateContract?.contractAttribution?.monthlyPaymentDepositKindType) && { monthly_payment_deposit_kind_type: updateContract.contractAttribution.monthlyPaymentDepositKindType }),
    ...(isNotNullOrUndefined(updateContract?.contractAttribution?.monthlyPaymentBankAccountNumber) && { monthly_payment_bank_account_number: updateContract.contractAttribution.monthlyPaymentBankAccountNumber }),
    ...(isNotNullOrUndefined(updateContract?.contractAttribution?.monthlyPaymentBankAccountName) && { monthly_payment_bank_account_name: updateContract.contractAttribution.monthlyPaymentBankAccountName }),
    ...(isNotNullOrUndefined(updateContract?.contractAttribution?.monthlyPaymentSendMoneyAmount) && { monthly_payment_send_money_amount: updateContract.contractAttribution.monthlyPaymentSendMoneyAmount }),
  };

  const contract: Contract = {
    ...(isNotNullOrUndefined(updateContract?.contractKind) && { contract_kind: updateContract.contractKind }),
    ...(isNotNullOrUndefined(updateContract?.paymentBank) && { payment_bank: updateContract.paymentBank }),
    ...(isNotNullOrUndefined(updateContract?.managementState) && { management_state: updateContract.managementState }),
    ...(isNotNullOrUndefined(updateContract?.normalContract) && { normal_contract: updateContract.normalContract }),
    ...(isNotNullOrUndefined(updateContract?.autoUpdate) && { auto_update: updateContract.autoUpdate }),
    ...(isNotNullOrUndefined(updateContract?.rentalItem) && { rental_item: updateContract.rentalItem }),
    ...(isNotNullOrUndefined(updateContract?.rentalItemContents) && { rental_item_contents: updateContract.rentalItemContents }),
    ...(isNotNullOrUndefined(updateContract?.initialProcedureType) && { initial_procedure_type: updateContract.initialProcedureType }),
    ...(!isEmpty(parkingUser) && { parking_user: parkingUser }),
    ...(!isEmpty(parkingContractor) && { parking_contractor: parkingContractor }),
    ...(!isEmpty(clientCompany) && { client_company: clientCompany }),
    ...(!isEmpty(lessor) && { lessor: lessor }),
    ...(!isEmpty(brokerCompany) && { broker_company: brokerCompany }),
    ...(!isEmpty(contractVehicle) && { contract_vehicle: contractVehicle }),
    ...(!isEmpty(contractCondition) && { contract_condition: contractCondition }),
    ...(!isEmpty(contractAttribution) && { contract_attribution: contractAttribution }),
  };

  return contract;
}

export const convertToApi = (data: any): object => {
  const convertData: any = {};
  Object.keys(data).forEach(key => {
    const snakeKey = toSnakeCase(key);
    if (data[key] !== undefined) {
      if (typeof data[key] === 'object' && data[key]) {
        const objData = convertToApi(data[key]);
        if (!isEmpty(objData)) convertData[snakeKey] = objData;
      } else {
        convertData[snakeKey] = data[key];
      }
    }
  });
  return convertData;
};
